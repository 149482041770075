<!-- ----------------------------------------------------------------------- -->
<!--
name    : WORK TASK HISTORY

type    : component

used by : inventory-map

uses    : none

route   : none
 -->
<!-- ----------------------------------------------------------------------- -->
<template>
  <v-card>
    <v-tabs v-model="tab_state" slider-color="primary">
      <v-tab key="open" ripple> Open Work Tasks </v-tab>
      <v-tab key="closed" ripple> Completed Work Tasks </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab_state" touchless class="py-2">
      <v-tab-item key="open">
        <v-card flat>
          <v-data-table :headers="openWorkTaskHeaders" :items="openWorkTasks">
            <template v-slot:item="props">
              <tr @click="onWorkTaskClick(props.item)">
                <td>{{ props.item.workcategory }}</td>
                <td>{{ props.item.worktype }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-tab-item>
      <v-tab-item key="closed">
        <v-card flat>
          <v-data-table
            :headers="closedWorkTaskHeaders"
            :items="closedWorkTasks">
            <template v-slot:item="props">
              <tr @click="onWorkTaskClick(props.item)">
                <td>{{ props.item.workcategory }}</td>
                <td>{{ props.item.worktype }}</td>
                <td>
                  {{
                    displayDateFormat(
                      props.item.completion_date,
                      tenantSettings.date_format
                    ) || '--'
                  }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
  // mixins
  import dateTimeHelperFunctions from '@/mixins/dateTimeHelperFunctions';
  import Localization from '@/mixins/localization';

  // services
  import Jobs from '@/services/Jobs.service.js';

  export default {
    name: 'WorkTaskHistory',
    mixins: [dateTimeHelperFunctions, Localization],
    props: {
      tree_uuid: {
        type: String,
        default: null,
      },
      tab: {
        type: String,
        default: '',
      },
      tenantSettings: {
        type: Object,
        required: true,
        default: () => {},
      },
    },
    data() {
      return {
        openWorkTaskHeaders: [
          {
            text: 'Work Category',
          },
          {
            text: 'Work Type',
          },
        ],
        closedWorkTaskHeaders: [
          {
            text: 'Work Category',
          },
          {
            text: 'Work Type',
          },
          {
            text: 'Date Completed',
          },
        ],
        openWorkTasks: [],
        closedWorkTasks: [],
        workTasks: [],
        tab_state: () => {
          return this.tab;
        },
      };
    },
    watch: {
      tree_uuid() {
        this.loadAndFilterWorkTasks();
      },
    },
    computed: {},
    created() {
      this.loadAndFilterWorkTasks();
    },
    methods: {
      async loadAndFilterWorkTasks() {
        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();

        // get work task history for tree
        if (this.tree_uuid) {
          this.workTasks = await Jobs.getWorkTasks(
            {
              tree_uuid: this.tree_uuid,
            },
            accessToken
          );

          this.openWorkTasks = this.workTasks.filter(
            (task) => task.status == 'not_complete'
          );
          this.closedWorkTasks = this.workTasks.filter(
            (task) => task.status == 'complete'
          );
        } else {
          console.log('no tree uuid passed in: ', this.tree_uuid);
        }
      },
      onWorkTaskClick(task) {
        if (task.workorder_uuid) {
          this.$router.push({
            name: 'WorkOrderView',
            params: {
              uuid: task.workorder_uuid,
            },
          });
        } else {
          this.$router.push({
            name: 'EstimateView',
            params: {
              uuid: task.estimate_uuid,
            },
          });
        }
      },
    },
  };
</script>

<style scoped>
  tr {
    cursor: pointer;
  }
</style>
