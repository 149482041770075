<!-- ----------------------------------------------------------------------- -->
<!--
name    : WORK TASK

type    : component

used by : work-task-list-inventory-map
          work-task-list-workorder-view

uses    : work-task-details
          header-view
          simple-dialog-template

route   : none
 -->
<!-- ----------------------------------------------------------------------- -->
<template>
  <div class="work-task">
    <!--Work Order List view  -->
    <v-row class="mt-n1 ml-0 pa-0" v-if="workorder_uuid && summary && worktask">
      <v-list-item-content class="ma-0" :loading="true">
        <v-list-item-title>
          <div v-if="species">{{ species }}</div>
          <div v-else>Not Mapped</div>
        </v-list-item-title>

        <v-list-item-subtitle id="card_subtitle">
          <div>{{ worktask.workcategory }}-{{ worktask.worktype }}</div>
        </v-list-item-subtitle>
        <v-card-text v-if="worktask.notes" class="worktask_notes">
          <a @click="showNotes(worktask.notes)">Notes:</a>
          {{ worktask.notes.slice(0, 20) + '...' }}
        </v-card-text>
        <v-card-actions class="ml-2 mt-0 mb-n4 pa-0">
          <!-- View Tree icon -->
          <v-btn
            icon
            class="ma-0 pa-0"
            color="button-primary"
            @click="treeClicked"
            :disabled="!worktask.species">
            <v-icon color="button-primary" class="mt-n3 pa-0">mdi-tree</v-icon>
          </v-btn>
          <v-btn
            icon
            class="ma-0 pa-0"
            color="button-primary"
            :disabled="workorder_closed"
            :loading="isWorking">
            <v-checkbox
              small
              class="mt-3 ml-2"
              v-model="completed"
              :ripple="false"
              color="button-primary"
              :disabled="workorder_closed"
              @change="toggleWorkTaskComplete">
            </v-checkbox>
          </v-btn>
        </v-card-actions>
      </v-list-item-content>
    </v-row>
    <div v-else-if="summary" @click="viewWorkTaskDetails()">
      <v-row>
        <v-col cols="6" md="4" class="item">
          <div class="subtitle-1 font-weight-bold">Description</div>
          <div class="font-weight-bold">{{ worktask.workcategory }}</div>
          <div class="mt-2">{{ worktask.worktype }}</div>
          <div v-if="worktask.notes" class="mt-2">
            {{ worktask.notes.slice(0, 20) + ' ...' }}
          </div>
        </v-col>
        <v-col cols="6" :md="proposal ? '3' : '2'" class="item">
          <div class="subtitle-1 font-weight-bold">Species</div>
          <div>{{ species || 'Not Mapped' }}</div>
        </v-col>
        <v-col v-if="proposal" cols="6" md="3" class="item">
          <div class="subtitle-1 font-weight-bold">Qty</div>
          <div>
            {{ pricing_method != 'HOURLY' ? originalWorkTask['units'] : '1' }}
          </div>
        </v-col>
        <v-col v-else cols="6" md="2" class="item">
          <div class="subtitle-1 font-weight-bold">
            {{ taskAttr.units_label }}
          </div>
          <div>
            {{
              formatUnit(
                taskAttr.units_label,
                originalWorkTask[taskAttr.units],
                tenantSettings.units_of_measurement
              )
            }}
          </div>
        </v-col>
        <v-col v-if="!proposal" cols="6" md="2" class="item">
          <div class="subtitle-1 font-weight-bold">Rate</div>
          <div>{{ formatCurrency(originalWorkTask.cost, currencySymbol) }}</div>
        </v-col>
        <v-col cols="6" md="2" class="item">
          <div class="subtitle-1 font-weight-bold">Sub-Total</div>
          <div>
            {{
              formatCurrency(
                this.getWorkTaskTotal(this.originalWorkTask),
                currencySymbol
              )
            }}
          </div>
        </v-col>
      </v-row>
    </div>
    <v-dialog v-model="opendialog" persistent max-width="600px">
      <v-card>
        <header-view
          dialog
          previous_page="NO_BACK_BUTTON"
          title="Work Task"
          closeBtn
          :closeAction="close"
          :btnOptions="taskButtons" />
        <v-card-text>
          <work-task-details
            v-model="worktask"
            ref="workTaskDetails"
            :pricing_method="pricing_method"
            v-bind:tenantSettings="tenantSettings"
            :proposal="proposal"
            :readonly="readonly" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <simple-dialog-template
      :open="showNotesDialog"
      dialogTitle="Work Task Notes"
      :dialogText="currentNotes"
      dialogButtonTwo="Close"
      @buttonTwo="showNotesDialog = false" />

    <v-snackbar v-model="snackbar" :timeout="5000">
      Record has been saved
      <v-btn class="button-primary" text @click="snackbar = false">
        Close
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
  // components
  import SimpleDialogTemplate from '@/components/simple-dialog-template';
  import HeaderView from '@/components/header-view';
  import WorkTaskDetails from '@/components/worktasks/work-task-details';

  // mixins
  import Forms from '@/mixins/forms';
  import Localization from '@/mixins/localization';

  // services
  import Jobs from '@/services/Jobs.service.js';

  export default {
    name: 'WorkTask',
    components: {
      'simple-dialog-template': SimpleDialogTemplate,
      'header-view': HeaderView,
      'work-task-details': WorkTaskDetails,
    },
    mixins: [Forms, Localization],
    props: {
      proposal: {
        type: Boolean,
        default: false,
      },
      value: {
        type: Object,
      },
      dialog: {
        type: Boolean,
        default: false,
      },
      summary: {
        type: Boolean,
        default: false,
      },
      readonly: {
        //disables any edits, does NOT control the status field
        type: Boolean,
        default: false,
      },
      workorder_uuid: {
        default: '',
        type: String,
      },
      workorder_closed: {
        default: null,
        type: Boolean,
      },
      pricing_method: {
        type: String,
        default: '',
      },
      tenantSettings: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        statuses: [
          {
            value: 'not_complete',
            text: 'Not Completed',
          },
          {
            value: 'complete',
            text: 'Completed',
          },
          {
            value: 'canceled',
            text: 'Canceled',
          },
        ],
        snackbar: false,
        worktask: {},
        opendialog: false,
        species: '',
        currentNotes: '',
        showNotesDialog: false,
        taskAttr: {},
        originalWorkTask: {},
        isWorking: false,
        completed: false,
      };
    },
    computed: {
      currencySymbol() {
        var symbol = this.tenantSettings.currency_symbol
          ? this.tenantSettings.currency_symbol
          : '$';
        return symbol;
      },
      total: function () {
        return Number.parseFloat(
          this.getWorkTaskTotal(this.originalWorkTask)
        ).toFixed(2);
      },
      subtotal: function () {
        return Number.parseFloat(
          this.getWorkTaskSubTotal(this.originalWorkTask)
        ).toFixed(2);
      },
      // eslint-disable-next-line vue/return-in-computed-property
      status: function () {
        for (var i = 0; i < this.statuses.length; i++) {
          if (this.statuses[i].value === this.worktask.status) {
            return this.statuses[i].text;
          }
        }
      },
      taskButtons: function () {
        if (!this.readonly) {
          return [
            {
              name: '',
              icon: 'mdi-check',
              btnColor: 'white',
              action: this.save,
            },
          ];
        } else {
          return [];
        }
      },
    },
    watch: {
      worktask() {
        this.$emit('input', this.worktask);
        if (this.worktask) {
          // set the worktask status checkbox (for workorderview)

          this.completed = this.worktask.status == 'complete' ? true : false;

          // set worktask species
          if (this.worktask.species && this.worktask.species.common_name) {
            this.species = this.worktask.species.common_name;
          }
        }
      },
      dialog() {
        this.opendialog = this.dialog;
      },
      value() {
        this.worktask = this.value;
      },
      pricing_method() {
        this.taskAttr = this.getTaskAttributes();
      },
    },
    async created() {
      // console.log("WORK-TASK.VUE ", "CREATED");

      this.worktask = this.value;

      // console.log("worktask:", this.worktask);
      // console.log("pricing_method:", this.pricing_method);

      this.taskAttr = this.getTaskAttributes();

      Object.assign(this.originalWorkTask, this.worktask); // copy props so changes can be undone
    },
    mounted() {
      this.$events.$on('worktaskSpeciesUpdated', () => {
        if (
          this.worktask &&
          this.worktask.species &&
          this.worktask.species.common_name
        ) {
          this.species = this.worktask.species.common_name;
        }
      });
    },
    beforeDestroy() {},
    methods: {
      getWorkTaskTotal(entry) {
        var total = 0;
        total = this.getWorkTaskSubTotal(entry) - entry.discount;
        return total;
      },
      getWorkTaskSubTotal(entry) {
        //returns work task subtotal, which is the amount before discounts are applied.
        var subtotal = 0;
        //manhour amount calculation
        if (entry.pricing_method == 'HOURLY') {
          //cost times units
          subtotal = entry.cost * entry.units;
        }
        //dbh amount calculation
        else if (entry.pricing_method == 'DBH') {
          //cost times dbh, times units
          subtotal = entry.cost * entry.dbh * entry.units;
        }
        //height amount calculation
        else if (entry.pricing_method == 'HEIGHT') {
          //cost times height, times units
          subtotal = entry.cost * entry.height * entry.units;
        }
        //quickprice amount calculation
        else if (entry.pricing_method == 'FLAT') {
          subtotal = entry.cost * entry.units;
        }
        //openbid amount calculation
        else if (entry.pricing_method == 'OPENBID') {
          subtotal = entry.cost * entry.units;
        }
        return subtotal;
      },
      viewWorkTaskDetails() {
        this.opendialog = true;

        if (this.$refs.workTaskDetails) {
          this.$refs.workTaskDetails.reset();
        }
      },
      showNotes(notes) {
        this.currentNotes = notes;
        this.showNotesDialog = true;
      },
      async save() {
        if (!this.$refs.workTaskDetails.isValid()) {
          return;
        }

        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();

        this.worktask.subtotal = Number.parseFloat(
          this.getWorkTaskSubTotal(this.worktask)
        ).toFixed(2);

        Object.assign(this.originalWorkTask, this.worktask); // commit changes

        let res = await Jobs.updateWorkTask(
          this.worktask.uuid,
          this.worktask,
          accessToken
        );

        if (res) {
          this.$emit('save');
          this.$emit('close');
          this.opendialog = false;
          this.snackbar = true;
        }
      },
      async close() {
        Object.assign(this.worktask, this.originalWorkTask); // undo changes
        this.$emit('close');
        this.opendialog = false;
      },
      async completeWorkTask(newStatus) {
        this.$emit('loading');
        this.worktask.status = newStatus;
        this.worktask.completion_date =
          newStatus == 'complete' ? new Date() : null;

        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();

        let res = await Jobs.updateWorkTask(
          this.worktask.uuid,
          this.worktask,
          accessToken
        );
        if (res) {
          this.$emit('save');
          this.snackbar = true;
        }
      },
      async toggleWorkTaskComplete() {
        // console.log("TOGGLE WORK TASK COMPLETE", this.worktask);
        this.isWorking = true;
        this.$emit('loading');
        if (this.worktask.status == 'complete') {
          this.worktask.status = 'not_complete';
        } else {
          this.worktask.status = 'complete';
          this.worktask.completion_date = new Date();
        }

        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();

        let res = await Jobs.updateWorkTask(
          this.worktask.uuid,
          this.worktask,
          accessToken
        );
        if (res) {
          this.$emit('save');
          this.snackbar = true;
          this.isWorking = false;
        }
      },
      treeClicked: function () {
        if (this.worktask.tree_uuid) {
          this.$events.$emit('treeMarkerSelect', this.worktask.tree_uuid);
        }
      },
      getTaskAttributes() {
        if (!this.pricing_method) {
          console.log(
            'pricing_method was not passed in: ',
            this.pricing_method
          );
          return;
        }

        var x = {};

        switch (this.pricing_method) {
          case 'HOURLY':
            x.units_label = 'Hours';
            x.units = 'units';
            x.show_units = true;
            break;
          case 'DBH':
            x.units_label = 'DBH';
            x.units = 'dbh';
            x.show_units = true;
            break;
          case 'HEIGHT':
            x.units_label = 'Height';
            x.units = 'height';
            x.show_units = true;
            break;
          case 'OPENBID':
            x.units_label = 'Quantity';
            x.units = 'units';
            x.show_units = false;
            break;
          case 'FLAT':
            x.units_label = 'Quantity';
            x.units = 'units';
            x.show_units = false;
            break;
        }

        return x;
      },
    },
  };
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped>
  table {
    width: 100%;
  }

  .item {
    min-width: 100px !important;
  }

  .v-btn__content {
    white-space: normal !important;
  }

  @media (max-width: 1023px) {
    .complete-btn {
      height: fit-content !important;
      min-height: 36px !important;
    }
  }

  .no-background-hover {
    background-color: transparent !important;
  }

  #card_subtitle {
    font-size: 0.9em;
  }

  .worktask_notes {
    padding: 0;
    color: #bbbbbb;
    font-size: 0.8em;
  }
</style>
