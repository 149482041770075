<!-- ----------------------------------------------------------------------- -->
<!--
name    :  WORK TASK SECTIONS LIST [ READONLY ]

about   :  this is used in views/components that need the worktask list split
           into sections. This is readonly, so no ability to add/edit

type    :  component

props:  :

used by :  AppEstimateProposal
           AppEstimateProposalPublic
           AppEstimateView (when estimate is locked/sent)

uses    :  simple-dialog-template
           work-task
           header-view

route   :  none
 -->
<!-- ----------------------------------------------------------------------- -->
<template>
  <v-container class="work-task-sections-list-readonly">
    <div class="sections-panel">
      <v-expansion-panels multiple v-model="sectionsPanel">
        <v-expansion-panel v-for="(section, i) in estimateSections" :key="i">
          <v-expansion-panel-header>
            <v-flex xs12 md2 v-on:click.stop>
              <v-text-field v-model="section.name" :readonly="true" />
            </v-flex>
            <v-spacer></v-spacer>
            <v-flex v-on:click.stop xs12 md2>
              <v-checkbox
                v-model="section.approved"
                :readonly="approved"
                v-if="proposal_view"
                label="Approved"
                @change="updateSectionAndEstTotal(i)"
                color="button-primary">
              </v-checkbox>
            </v-flex>
            <v-flex xs12 md2 class="pt-3 mr-7">
              <div class="title">Section Total</div>
              <div class="subtitle">
                {{ currencySymbol + (sectionTotals[i] || 0) }}
              </div>
            </v-flex>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-card flat v-if="worktasks && worktasks.length > 0">
              <v-list three-line>
                <div
                  v-for="(task, index) in worktasks"
                  :key="worktasks[index].uuid">
                  <v-divider
                    v-if="
                      worktasks[index].estimate_section_uuid == section.uuid
                    "></v-divider>
                  <v-list-item
                    v-if="
                      worktasks[index].estimate_section_uuid == section.uuid
                    "
                    class="px-0">
                    <v-list-item-content>
                      <work-task
                        v-bind:pricing_method="worktasks[index].pricing_method"
                        v-model="worktasks[index]"
                        summary
                        @save="forceReload()"
                        @loading="showLoading()"
                        :readonly="true"
                        v-bind:workorder_uuid="
                          workorder ? workorder.uuid : null
                        "
                        v-bind:workorder_closed="workorder_closed"
                        v-bind:proposal="proposal_view"
                        v-bind:tenantSettings="tenantSettings">
                      </work-task>
                    </v-list-item-content>
                  </v-list-item>
                </div>
              </v-list>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <div class="estimate-totals" v-if="taxInfo">
      <v-container style="padding-right: 32px">
        <v-layout row class="mt-5">
          <v-spacer></v-spacer>
          <v-flex xs4>
            <div v-if="proposal_view" class="headline text-right">
              Subtotal: {{ currencySymbol + estimateSubtotal }}
            </div>
            <div v-if="proposal_view" class="headline text-right">
              Tax Rate{{ taxRate }}%
            </div>
            <div class="headline text-right">
              Estimate Total: {{ currencySymbol + estimateTotal }}
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
  </v-container>
</template>

<script>
  // components
  import SimpleDialogTemplate from '@/components/simple-dialog-template';
  import WorkTask from '@/components/worktasks/work-task';
  import HeaderView from '@/components/header-view';

  // mixins
  import Localization from '@/mixins/localization';

  // services
  import Jobs from '@/services/Jobs.service.js';
  import Tenants from '@/services/Tenants.service.js';

  export default {
    name: 'WorkTaskSectionsListReadOnly',
    components: {
      // eslint-disable-next-line vue/no-unused-components
      'simple-dialog-template': SimpleDialogTemplate,
      'work-task': WorkTask,
      // eslint-disable-next-line vue/no-unused-components
      'header-view': HeaderView,
    },
    mixins: [Localization],
    props: {
      proposal_view: {
        type: Boolean,
        default: false,
      },
      approved: {
        // used only for allowing approved checkboxes to function until the estimate has been approved
        type: Boolean,
        default: false,
      },
      estimate: {
        type: Object,
        default: () => {},
      },
      workorder: {
        type: Object,
        default: () => {},
      },
      workorder_closed: {
        default: null,
        type: Boolean,
      },
      tenantSettings: {
        type: Object,
        default: () => {},
      },
      trees: {
        type: Array, // used for showing species associated to work tasks
        default: () => [],
      },
    },
    data() {
      return {
        catalogSelected: false,
        deleteUUID: '',
        estimateSections: [],
        estimateSubtotal: '0.00',
        newWorkOrder: {},
        notifyDialog: false,
        notifyDialogText: '',
        panels: [],
        sectionsPanel: [],
        sectionTotals: [],
        speciesList: [],
        taxInfo: null,
        worktaskDeleteConfirmDialog: false,
        worktasks: [],
        workTaskToggle: 0,
        worktype: null,
        worktypes: [],
      };
    },
    computed: {
      currencySymbol() {
        var symbol = this.tenantSettings.currency_symbol
          ? this.tenantSettings.currency_symbol
          : '$';
        return symbol;
      },
      estimateTotal() {
        if (this.proposal_view) {
          var tax_rate = this.taxInfo.tax_rate
            ? this.taxInfo.tax_rate * 0.01
            : 0;
          var total =
            Number.EPSILON +
            parseFloat(this.estimateSubtotal) +
            this.estimateSubtotal * tax_rate;
          var roundedTotal = Math.round(total * 100) / 100;
          return roundedTotal.toFixed(2);
        } else {
          return this.estimateSubtotal;
        }
      },
      taxRate() {
        var tax_type = this.taxInfo.tax_type
          ? ' (' + this.taxInfo.tax_type + ')'
          : '';
        var tax_rate = this.taxInfo.tax_rate ? this.taxInfo.tax_rate : '0.0';
        return tax_type + ': ' + tax_rate;
      },
      workTaskTotal: function () {
        let total = 0;
        let i = 0;
        for (i = 0; i < this.worktasks.length; i++) {
          total += parseInt(this.worktasks[i].subtotal);
        }
        return total;
      },
      sections: function () {
        var i = 0;
        for (i = 0; i < this.worktasks.length; i++) {
          if (!this.worktasks[i].section) {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.worktasks[i].section = 'Ungrouped';
          }
        }
        const sections = this.worktasks.map((x) => x.section);

        return [...new Set(sections)]; //note the javascript spread operator (...) (hate this syntax)
      },
    },
    async created() {
      // all components that use this component must pass in an estimate
      if (!this.estimate && !this.estimate.tenant_uuid) {
        console.log('estimate or estimate tenant uuid not passed in!');
        return;
      }

      // validate that we can get the tenant uuid
      // NOTE: Do not use $auth to get this tenant uuid,
      // these are supposed to be unsecured!  tenant uuid MUST be
      // acquired from the estimate prop
      var tenant_uuid = this.estimate.tenant_uuid;

      if (!tenant_uuid) {
        console.log(
          'could not get tenant uuid (must come from estimate!!), aborting...'
        );
        return;
      }

      // Get tenant tax rate & type
      this.loadTaxInfo(tenant_uuid);

      // Get tenant species (must be done before loadWorkTasks)
      await this.loadTenantSpecies(tenant_uuid);

      // Get worktasks via tenant uuid we got from estimate prop
      // NOTE: Do not use $auth to get this tenant uuid,
      // these are supposed to be unsecured!  tenant uuid MUST be
      // acquired from the estimate prop
      await this.loadWorkTasks(tenant_uuid);

      // Get estimate sections via estimate uuid
      await this.loadEstimateSections(this.estimate.uuid);

      // by default open the sections for viewing
      this.sectionsPanel = this.setOpenSections();

      this.updateTotals();

      this.$events.$on('worktaskreload', this.forceReload);
    },
    watch: {
      trees() {
        this.matchWorkTasksAndSpecies();
      },
      estimateSubtotal(newVal) {
        // even though the estimate sections of this component
        // are readonly for the user, this will update any outdated
        // estimate totals in db
        this.$emit('totalpriceupdate', newVal);
      },
    },
    methods: {
      async loadTaxInfo(tenant_uuid) {
        this.taxInfo = await Tenants.getTaxInfo(tenant_uuid);
      },
      async loadWorkTasks(tenant_uuid) {
        this.worktasks = await Jobs.getWorkTasksByParams({
          tenant_uuid: tenant_uuid,
          client_uuid: this.estimate.client_uuid,
          job_uuid: this.estimate.job_uuid,
          estimate_uuid: this.estimate.uuid,
          workorder_uuid: this.workorder ? this.workorder.uuid : null,
        });

        // console.log("worktasks:", this.worktasks);

        // updates AppEstimateView preview estimate button if tasks > 0 :
        this.$emit('worktasksloaded', this.worktasks);

        // get species list for the species column of table
        this.matchWorkTasksAndSpecies();
      },
      async loadEstimateSections(estimate_uuid) {
        var res = await Jobs.getEstimateSectionsByEstimate(estimate_uuid);

        if (res) {
          this.estimateSections = res;
        } else {
          this.estimateSections = [];
        }

        // console.log( "estimate sections: ", this.estimateSections );
      },

      setOpenSections: function () {
        // console.log( "estimateSections :", this.estimateSections );
        if (this.estimateSections && this.estimateSections.length > 0) {
          // console.log( "estimateSections pass " );
          var resp = Array(this.estimateSections.length)
            .fill(1)
            .map((x, y) => y);
          // console.log( "estimateSections resp: ", resp );
          return resp;
        } else {
          return [];
        }
      },
      updateTotals: function () {
        this.getSectionTotals();
      },

      getSectionTotals: function () {
        var totals = [];
        var estimate_total = 0;
        var i = 0;
        var x = 0;
        for (i = 0; i < this.estimateSections.length; i++) {
          let total = 0;
          for (x = 0; x < this.worktasks.length; x++) {
            if (
              this.worktasks[x].estimate_section_uuid ==
              this.estimateSections[i].uuid
            ) {
              total += Number.parseFloat(
                this.getWorkTaskTotal(this.worktasks[x])
              );
            }
          }
          // add estimate section total to estimate section total array
          totals.push(total.toFixed(2));

          // add total to overall estimate total
          // note: we only want to add the section total to the overall total
          // if we are a) not in proposal view or b) in proposal view and that
          // estimate section is approved
          if (
            this.proposal_view == false ||
            (this.proposal_view && this.estimateSections[i].approved)
          ) {
            estimate_total += total;
          }
        }

        this.sectionTotals = totals;
        this.estimateSubtotal = estimate_total.toFixed(2);
      },
      getWorkTaskTotal(entry) {
        //returns work task subtotal, which is the amount before discounts are applied.
        var subtotal = 0;
        //manhour amount calculation
        if (entry.pricing_method == 'HOURLY') {
          //cost times units
          subtotal = entry.cost * entry.units;
        }
        //dbh amount calculation
        else if (entry.pricing_method == 'DBH') {
          //cost times dbh, times units
          subtotal = entry.cost * entry.dbh * entry.units;
        }
        //height amount calculation
        else if (entry.pricing_method == 'HEIGHT') {
          //cost times height, times units
          subtotal = entry.cost * entry.height * entry.units;
        }
        //quickprice amount calculation
        else if (entry.pricing_method == 'FLAT') {
          subtotal = entry.cost * entry.units;
        }
        //openbid amount calculation
        else if (entry.pricing_method == 'OPENBID') {
          subtotal = entry.cost * entry.units;
        }

        // return total and subtract off discount
        return subtotal - entry.discount;
      },

      async updateSectionAndEstTotal(index) {
        // // Get the access token from the auth wrapper
        // const accessToken = await this.$auth.getTokenSilently();

        // console.log(this.estimateSections[index], index);

        // recalculate the Estimate Total based on approved checkbox update
        this.updateTotals();

        /// save the data
        var res = await Jobs.approveOrDeclineEstimateSection(
          this.estimateSections[index].uuid,
          this.estimateSections[index].approved
        );
        // generate the PDF
        if (res) {
          this.$emit('approvalsChanged');
        }
      },
      openAllPanels() {
        this.panels = [...Array(this.sections).keys()].map((k, i) => i);
      },

      async forceReload() {
        // console.log("forceReload WorkTaskList readonly");

        // validate that we can get the tenant uuid
        // NOTE: Do not use $auth to get this tenant uuid,
        // these are supposed to be unsecured!  tenant uuid MUST be
        // acquired from the estimate prop
        var tenant_uuid = this.estimate.tenant_uuid;

        if (!tenant_uuid) {
          console.log(
            'could not get tenant uuid (must come from estimate!!), aborting...'
          );
          return;
        }

        // Get worktasks via tenant uuid we got from estimate prop
        // NOTE: Do not use $auth to get this tenant uuid,
        // these are supposed to be unsecured!  tenant uuid MUST be
        // acquired from the estimate prop
        await this.loadWorkTasks(tenant_uuid);

        // Get estimate sections via estimate uuid
        await this.loadEstimateSections(this.estimate.uuid);

        // by default open the sections for viewing
        this.sectionsPanel = this.setOpenSections();

        this.updateTotals();

        // this is used only in the AppWorkorderView component. It is responsible
        // for updating status in the header view after changes to individual
        // worktasks
        this.$emit('onReload');
      },
      showLoading() {
        this.$emit('loading');
      },
      //load tenant's species list
      async loadTenantSpecies(tenant_uuid) {
        // get species
        var res_species = await Tenants.getTenantSpecies(tenant_uuid, {
          active: true,
        });

        if (res_species) {
          if (!(res_species.length > 0)) {
            console.log('tenant species list was empty');
            return;
          }

          //get just the species uuids
          var speciesUUIDs = res_species.map((x) => x.species_uuid);

          //this should probable just be called for each uuid rather than as a group so that
          //there doesn't have to be the rematching stuff
          //translate the uuids in the master list to get common and latin name

          var res_lookup = await Tenants.getMasterSpeciesBySpeciesLookup({
            species_uuids: speciesUUIDs,
          });

          if (res_lookup) {
            //save species response to species data table
            this.speciesList = res_lookup;
            //this rematches the tenant list uuid to the translated master list display
            //this is for alt name display and view purposes, but could probably be done in a much smarter way
            this.speciesList.forEach((row) => {
              var match = res_species.find((tenantSpecies) => {
                return tenantSpecies.species_uuid === row.uuid;
              });
              row.tenantSpeciesUUID = match.uuid;
              //if match has an alternate common name, make the row common name that
              row.common_name = match.alt_common_name
                ? match.alt_common_name
                : row.common_name;
              row.active = match.active;
            });
          } else {
            console.log('error doing master species lookup');
          }
        } else {
          console.log('could not load tenant species');
        }
      },
      matchWorkTasksAndSpecies: function () {
        this.worktasks.forEach((entry) => {
          if (this.trees && this.trees.length) {
            this.trees.forEach((tree) => {
              if (entry.tree_uuid == tree.uuid) {
                this.$set(
                  entry,
                  'species',
                  this.speciesList.find(
                    (x) => x.tenantSpeciesUUID == tree.tenant_species_uuid
                  )
                );
              }
            });
          }
        });
        this.$events.$emit('worktaskSpeciesUpdated');
      },
    },
  };
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped>
  .v-expansion-panel {
    width: auto;
  }

  .taskDots {
    margin-right: 0 !important;
  }

  .v-expansion-panel-content__wrap {
    padding: 16px 0 !important;
  }

  .v-expansion-panels > :first-child {
    margin-top: 16px !important;
  }
</style>
