<template>
  <!-- eslint-disable vue/no-mutating-props -->
  <v-dialog v-model="show" persistent width="500">
    <v-card>
      <header-view
        dialog
        previous_page="NO_BACK_BUTTON"
        title="Speices List Added"
        closeBtn
        :closeAction="close" />
      <v-card-text>
        <v-container>
          <v-row align="center" justify="center">
            <!--show loading symbol or import post response summary-->
            <v-progress-circular
              :indeterminate="indeterminate"
              color="primary"
              :value="progress"
              :rotate="270"
              :key="progress"
              :size="75"
              v-if="!loaded"
              >{{ Math.round(progress) }}</v-progress-circular
            >
            <div v-if="loaded">
              {{ loadedInfo }}
            </div>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
  <!-- eslint-enable -->
</template>

<script>
  import HeaderView from '@/components/header-view';

  export default {
    name: 'AddSpeciesStatus',
    components: {
      'header-view': HeaderView,
    },
    props: {
      info: {
        type: Object,
        default: () => {},
      },
      show: {
        default: false,
        type: Boolean,
      },
      loaded: {
        default: false,
        type: Boolean,
      },
      progress: {
        default: 0,
        type: Number,
      },
      indeterminate: {
        default: false,
        type: Boolean,
      },
    },
    data() {
      return {};
    },
    computed: {
      //convert results of importing to tenant list to totals data
      loadedInfo: function () {
        var s = this.info;
        var result = '';
        if (s.hasOwnProperty('addedSpecies')) {
          if (s.addedSpecies.length > 0) {
            result +=
              'Successfully added ' +
              s.addedSpecies.length +
              ' new species to your list.\n';
          } else {
            result += 'No species were added.\n';
          }
          if (s.duplicates.length > 0) {
            result +=
              'Successfully updated ' +
              s.duplicates.length +
              ' existing (duplicate) species.\n';
          }
          if (s.errors.length > 0) {
            result +=
              'There were errors uploading ' + s.errors.length + ' species.\n';
          }
        }
        return result;
      },
    },
    mounted() {},
    methods: {
      close: function () {
        this.$emit('close');
      },
    },
  };
</script>
