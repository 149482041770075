var instance = null;

function TreeOverlayFactory() {
  if (instance) {
    return instance;
  }

  var deleteTreeButton = null;

  // TODO: remove the eslint override and solve this undefined variable warning correctly.
  // eslint-disable-next-line no-undef
  TreeOverlay.prototype = Object.create(google.maps.OverlayView.prototype);

  function TreeOverlay(tree, map, position, events, localization) {
    this.map_ = map;
    this.div_ = null;
    // TODO: remove the eslint override and solve this undefined variable warning correctly.
    // eslint-disable-next-line no-undef
    this.position_ = new google.maps.LatLng(position.lat, position.lng);

    var div = document.createElement('div');
    div.style.borderStyle = 'none';
    div.style.borderWidth = '0px';
    div.style.position = 'absolute';

    var sizeX = 350;
    var verticalTranslate = 196; // old value: 226 // magic number (comes from height of content + spacing)

    var popup = document.createElement('div');
    popup.className = 'popup';
    popup.style.transform = 'translate(-50%, -' + verticalTranslate + 'px)';
    popup.style.width = sizeX + 'px';

    var titleBar = document.createElement('div');
    titleBar.className = 'popup-title-bar';
    titleBar.innerHTML =
      '<div class="popup-title">' +
      (tree.species ? tree.species.common_name : 'Species Unknown') +
      '</div><div class="spacer"></div>';

    var buttonBar = document.createElement('div');

    deleteTreeButton = document.createElement('div');
    deleteTreeButton.title = 'Delete Tree';
    deleteTreeButton.id = 'deleteTreeButton';
    deleteTreeButton.className =
      'v-btn--loading v-btn v-btn--fab v-btn--flat v-btn--icon v-btn--round theme--light v-size--default white--text';
    deleteTreeButton.innerHTML =
      '\
    <span class="v-btn__content">\
    <i aria-hidden="true" class="v-icon notranslate mdi mdi-delete theme--light"></i> \
    </span>\
    <span id="deleteButtonLoadingIcon" class="v-btn__loader"> \
    <div role="progressbar" aria-valuemin="0" aria-valuemax="100" class="v-progress-circular v-progress-circular--indeterminate" style="height: 23px; width: 23px;"> \
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="21.904761904761905 21.904761904761905 43.80952380952381 43.80952380952381" style="transform: rotate(0deg);"> \
    <circle fill="transparent" cx="43.80952380952381" cy="43.80952380952381" r="20" stroke-width="3.8095238095238093" stroke-dasharray="125.664" stroke-dashoffset="125.66370614359172px" class="v-progress-circular__overlay"> \
    </circle> \
    </svg> \
    <div class="v-progress-circular__info"></div> \
    </div> \
    </span>';
    if (events && events.onDelete) {
      deleteTreeButton.onclick = events.onDelete;
      deleteTreeButton.style.display = 'inline-flex';
    } else {
      deleteTreeButton.style.display = 'none';
    }
    buttonBar.appendChild(deleteTreeButton);

    var treeDetailsButton = document.createElement('div');
    treeDetailsButton.title = 'View Tree Details';
    treeDetailsButton.className =
      'v-btn v-btn--fab v-btn--flat v-btn--icon v-btn--round theme--light v-size--default white--text';
    treeDetailsButton.innerHTML =
      '<span class="v-btn__content"><i aria-hidden="true" class="v-icon notranslate mdi mdi-tree theme--light"></i></span>';
    treeDetailsButton.onclick = events.onTreeDetails;
    buttonBar.appendChild(treeDetailsButton);

    var closeWindowButton = document.createElement('div');
    closeWindowButton.className =
      'v-btn v-btn--fab v-btn--flat v-btn--icon v-btn--round theme--light v-size--default white--text';
    closeWindowButton.innerHTML =
      '<span class="v-btn__content"><i aria-hidden="true" class="v-icon notranslate mdi mdi-close theme--light"></i></span>';
    closeWindowButton.onclick = events.onClose;
    buttonBar.appendChild(closeWindowButton);

    titleBar.appendChild(buttonBar);
    popup.appendChild(titleBar);

    var popupContent = document.createElement('div');
    popupContent.className = 'popup-content v-card__text';
    popupContent.innerHTML =
      '<div class="container grid-list-md">' +
      '<div class="row"><b class="flex xs12 md6 pl-10">DBH:</b><div class="text-right pr-8 flex xs12 md6">' +
      (tree.dbh ? tree.dbh + ' ' + localization.dbh : '-') +
      '</div></div>' +
      '<div class="row"><b class="flex xs12 md6 pl-10">Height:</b><div class="text-right pr-8 flex xs12 md6">' +
      (tree.height ? tree.height + ' ' + localization.height : '-') +
      '</div></div>' +
      '</div>';

    popup.appendChild(popupContent);

    div.appendChild(popup);

    this.div_ = div;

    // TODO: remove the eslint override and solve this undefined variable warning correctly.
    // eslint-disable-next-line no-undef
    google.maps.OverlayView.preventMapHitsAndGesturesFrom(this.div_);
    this.setMap(map);
  }

  TreeOverlay.prototype.onAdd = function () {
    this.getPanes().overlayMouseTarget.appendChild(this.div_);
  };

  TreeOverlay.prototype.draw = function () {
    this.bounds_ = this.map.getBounds();

    var overlayProjection = this.getProjection();
    var position = overlayProjection.fromLatLngToDivPixel(this.position_);

    var div = this.div_;
    div.style.left = position.x + 'px';
    div.style.top = position.y + 'px';
  };

  TreeOverlay.prototype.onRemove = function () {
    this.div_.parentNode.removeChild(this.div_);
    this.div_ = null;
  };

  TreeOverlay.prototype.showDeleteButtonSpinner = function () {
    if (deleteTreeButton) {
      deleteTreeButton.style.display = 'inline-flex';
      deleteTreeButton.classList.add('v-btn--loading');

      var deleteButtonLoadingIcon = deleteTreeButton.querySelector(
        '#deleteButtonLoadingIcon'
      );

      if (deleteButtonLoadingIcon) {
        deleteButtonLoadingIcon.style.display = 'inline-flex';
      }
    }
  };

  TreeOverlay.prototype.setDeleteButtonVisiblity = function (
    isShown,
    readonly
  ) {
    if (deleteTreeButton) {
      if (readonly) {
        deleteTreeButton.style.display = 'none';
        return;
      }

      if (deleteTreeButton) {
        deleteTreeButton.classList.remove('v-btn--loading');

        if (isShown) {
          deleteTreeButton.style.display = 'inline-flex';
        } else {
          deleteTreeButton.style.display = 'none';
        }
      }

      var deleteButtonLoadingIcon = deleteTreeButton.querySelector(
        '#deleteButtonLoadingIcon'
      );

      if (deleteButtonLoadingIcon) {
        deleteButtonLoadingIcon.style.display = 'none';
      }
    }
  };

  instance = TreeOverlay;
  return instance;
}

export default TreeOverlayFactory;
