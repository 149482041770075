// src/services/Notifications.service.js

import axios from 'axios';
import {uuid} from 'vue-uuid';

export default {
  created() {
    // handle api call errors
    axios.interceptors.response.use(
      (response) => response,
      (err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else if (err.request) {
          console.log(err.request);
        } else {
          console.log(err);
        }
      }
    );
  },

  // *****************************************************/
  //                    CREATE
  // *****************************************************/

  // Note: unsecured!! needed for estimate proposal link
  async createNotification(data) {
    // add new uuid
    if (!data.uuid) {
      data.uuid = uuid.v4();
    }

    let res = await axios({
      method: 'POST',
      url: '/service_notifications/notifications/create',
      data: data,
      // unsecured route
    });
    return res.data;
  },

  async createEmail(data) {
    const res = await axios({
      method: 'POST',
      url: '/service_messaging/email/create',
      data: data,
      // unsecured route
    });
    return res.data;
  },
};
