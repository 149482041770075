import axios from 'axios';
import {uuid} from 'vue-uuid';

export default {
  data() {
    return {
      addSpeciesResults: {
        addedSpecies: [],
        duplicates: [],
        errors: [],
      },
      speciesRegions: [
        {text: 'All', value: 'all'},
        {text: 'Australia', value: 'Australia'},
        {text: 'Central Florida (US)', value: 'Central Florida (US)'},
        {text: 'Coastal Plain (US)', value: 'Coastal Plain (US)'},
        {text: 'Inland Empire (US)', value: 'Inland Empire (US)'},
        {text: 'Inland Valley (US)', value: 'Inland Valley (US)'},
        {text: 'Interior West (US)', value: 'Interior West (US)'},
        {text: 'Lower Midwest (US)', value: 'Lower Midwest (US)'},
        {text: 'Midwest (US)', value: 'Midwest (US)'},
        {text: 'North (US)', value: 'North (US)'},
        {text: 'Northeast (US)', value: 'Northeast (US)'},
        {
          text: 'Northern California Coast (US)',
          value: 'Northern California Coast (US)',
        },
        {text: 'Pacific Northwest (US)', value: 'Pacific Northwest (US)'},
        {text: 'South (US)', value: 'South (US)'},
        {
          text: 'Southern California Coast (US)',
          value: 'Southern California Coast (US)',
        },
        {text: 'Southwest Desert (US)', value: 'Southwest Desert (US)'},
        {
          text: 'Temperate Interior West (US)',
          value: 'Temperate Interior West (US)',
        },
        {text: 'Tropical (US)', value: 'Tropical (US)'},
        {text: 'United Kingdom', value: 'United Kingdom'},
      ],
    };
  },
  computed: {
    totalPosts: function () {
      var a = this.addSpeciesResults;
      var total = a.addedSpecies.length + a.duplicates.length + a.errors.length;
      return total;
    },
  },
  created() {
    // handle api call errors
    axios.interceptors.response.use(
      (response) => response,
      (err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else if (err.request) {
          console.log(err.request);
        } else {
          console.log(err);
        }
      }
    );
  },
  methods: {
    //import species from array where all entries have at least latin and common
    async addTenantSpecies(selectedSpecies) {
      this.addSpeciesResults = {
        addedSpecies: [],
        duplicates: [],
        errors: [],
      };
      //for all chosen species, check if they have a uuid or if they already exist in the master list
      //if they don't create an unverified master species
      var resolvedSpecies = await Promise.all(
        selectedSpecies.map(async (species) => {
          species.latin_name = this.capitalize(species.latin_name);
          species.common_name = this.capitalize(species.common_name);
          if (!species.uuid) {
            var speciesUUIDResponse = await this.getSpeciesUUID(
              species.latin_name
            );
            species.uuid = speciesUUIDResponse.uuid;
            if (!speciesUUIDResponse.found) {
              this.addMasterSpecies([species], false);
            }
          }
          return Promise.resolve(species);
        })
      );

      var tenant_uuid = this.$auth.userProfile.tenant_uuid;
      var tenantSpecies = resolvedSpecies.map((x) => {
        return {
          uuid: uuid.v4(),
          species_uuid: x.uuid,
          alt_common_name: x.common_name,
          tenant_uuid: tenant_uuid,
          active: true,
        };
      });
      //recursively add all species
      return this.addTenantRecursive(tenantSpecies, 0);
    },

    async addTenantRecursive(speciesList, index) {
      // Get the access token from the auth wrapper
      const accessToken = await this.$auth.getTokenSilently();

      return new Promise((resolve) => {
        axios({
          method: 'POST',
          url: '/service_tenants/tenant_species/create_series',
          data: {
            species: speciesList.slice(index * 100, (index + 1) * 100),
          },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
          .then((response) => {
            //push the contents of the response arrays into the results arrays

            this.addSpeciesResults.addedSpecies.push(
              ...response.data.addedSpecies
            );
            this.addSpeciesResults.duplicates.push(...response.data.duplicates);
            this.addSpeciesResults.errors.push(...response.data.errors);
          })
          .finally(async () => {
            if ((index + 1) * 100 < speciesList.length) {
              resolve(this.addTenantRecursive(speciesList, index + 1));
            } else {
              resolve(this.addSpeciesResults);
            }
          });
      });
    },

    //start a loop to have multiple threads of adding species to master list
    addMasterSpecies: async function (speciesList, verified, region = []) {
      this.addSpeciesResults = {
        addedSpecies: [],
        duplicates: [],
        errors: [],
      };
      var allResults = [];
      for (var i = 0; i < Math.ceil(speciesList.length / 20); i++) {
        allResults.push(
          this.addMasterRecursive(speciesList, verified, i * 20, region)
        );
      }
      await Promise.all(allResults);
      return this.addSpeciesResults;
    },
    //recursively add species to master to not overload server resources
    async addMasterRecursive(speciesList, verified, index, region) {
      var s = speciesList[index];
      var newMasterSpecies = {
        latin_name: this.capitalize(s.latin_name),
        common_name: this.capitalize(s.common_name),
        verified: verified,
        //if uuid is provided, use that, otherwise create new one
        uuid: s.uuid || uuid.v4(),
        region: region ? region : [],
      };

      // Get the access token from the auth wrapper
      const accessToken = await this.$auth.getTokenSilently();

      return new Promise((resolve) => {
        axios({
          method: 'POST',
          url: '/service_tenants/master_species/create',
          data: newMasterSpecies,
          headers: {Authorization: `Bearer ${accessToken}`},
        })
          .then((response) => {
            this.addSpeciesResults.addedSpecies.push(
              ...response.data.addedSpecies
            );
            this.addSpeciesResults.duplicates.push(...response.data.duplicates);
            this.addSpeciesResults.errors.push(...response.data.errors);
          })
          .finally(() => {
            //if the next index is not greater than the list length and is not a multiple of 20,
            //call the function again with new index
            if (index + 1 < speciesList.length && (index + 1) % 20 != 0) {
              resolve(
                this.addMasterRecursive(
                  speciesList,
                  verified,
                  index + 1,
                  region
                )
              );
            }
            //else resolve the results object, which will bubble up through all previous recursive calls
            else {
              resolve(this.addSpeciesResults);
            }
          });
      });
    },
    //check to see if latin name already exists in master, if it doesn't then add it as unverified
    async getSpeciesUUID(latin_name) {
      // Get the access token from the auth wrapper
      const accessToken = await this.$auth.getTokenSilently();

      return new Promise((resolve, reject) => {
        axios({
          method: 'GET',
          url: '/service_tenants/master_species/latin_name/' + latin_name,
          params: {latin_name: latin_name},
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
          .then((response) => {
            //there was a matching latin name

            if (response.data && response.data.uuid) {
              resolve({
                status: 'successful',
                found: true,
                uuid: response.data.uuid,
              });
              //there was no matching latin name
            } else {
              resolve({
                status: 'successful',
                found: false,
                uuid: uuid.v4(),
              });
            }
          })
          .catch((error) => {
            console.log('error checking master');
            reject({status: 'not successful', error: error});
          });
      });
    },
    //capitalize every word of string s
    capitalize(s) {
      /*return s
        .split(' ')
        .map(s => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');*/
      return s.charAt(0).toUpperCase() + s.substring(1);
    },
    async getSingleTenantSpecies(uuid) {
      // Get the access token from the auth wrapper
      const accessToken = await this.$auth.getTokenSilently();
      var url = '/service_tenants/tenant_species/' + uuid;
      var species = {
        tenantSpeciesUUID: uuid,
      };

      const tenantResponse = await axios({
        method: 'GET',
        url: url,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (tenantResponse) {
        species.species_uuid = tenantResponse.data.species_uuid;
        species.alt_common_name = tenantResponse.data.alt_common_name;
        species.toggleAttr = tenantResponse.data.active;

        let payload = {
          species_uuids: [tenantResponse.data.species_uuid],
        };

        const masterResponse = await axios({
          method: 'POST',
          url: '/service_tenants/master_species/speciesLookup/',
          data: payload,
          // no auth needed, unsecured
        });

        if (masterResponse) {
          species.common_name = masterResponse.data[0].common_name;
          species.latin_name = masterResponse.data[0].latin_name;
          species.region = masterResponse.data[0].region;
        }
        return species;
      }
    },
    async getSingleMasterSpecies(uuid) {
      // Get the access token from the auth wrapper
      const accessToken = await this.$auth.getTokenSilently();
      var url = '/service_tenants/master_species/' + uuid;
      var species = {};
      const response = await axios({
        method: 'GET',
        url: url,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      species.common_name = response.data.common_name;
      species.latin_name = response.data.latin_name;
      species.toggleAttr = response.data.verified;
      species.region = response.data.region;
      return species;
    },
  },
};
