import Notifications from '@/services/Notifications.service.js';
import {uuid} from 'vue-uuid';

const email = {
  methods: {
    async createEmail(params) {
      if (!params) {
        console.log('createEmail: params cannot be empty');
        return;
      }

      // set tenant (email sender) details
      if (!params.logo && this.$auth.tenantProfile.logo && params) {
        params.logo = this.$auth.tenantProfile.logo;
      }
      params.tenant_name = this.$auth.tenantProfile.tenant_name;
      params.contact_phone = this.$auth.tenantProfile.contact_phone;
      params.contact_email = this.$auth.tenantProfile.contact_email;

      // send email
      const res = await Notifications.createEmail(
        params
        // unsecured route
      );

      if (res) {
        console.log(res);
      }
    },
    async createEvent(params) {
      if (this.$auth.tenantProfile.logo && params) {
        params.logo = this.$auth.tenantProfile.logo;
      } else if (params) {
        params.logo =
          'https://s3.amazonaws.com/ss-usa/companies/MzawMDGzMDc3AgA/uploads/Logos/TP-Jobs-RGB.png';
      }

      params.uuid = uuid.v4();

      params.tenant_name = this.$auth.tenantProfile.tenant_name;
      params.tenant_uuid = this.$auth.tenantProfile.uuid;
      params.contact_phone = this.$auth.tenantProfile.contact_phone;
      params.contact_email = this.$auth.tenantProfile.contact_email;

      params.recipients = [this.$auth.tenantProfile.contact_email];

      var res = await Notifications.createNotification(
        params
        // unsecured route
      );

      if (res) {
        // console.log("heres the email results")
        // console.log(res)
        // console.log("emitting event")
        this.$events.$emit('NotificationsUpdated', res);
      }
    },

    async createEventPublic(params) {
      params.uuid = uuid.v4();

      var res = await Notifications.createNotification(
        params
        // unsecured route
      );

      if (res) {
        // console.log("heres the email results")
        // console.log(res)
        // console.log("emitting event")
        this.$events.$emit('NotificationsUpdated', res);
      }
    },
  },
  created() {},
};

export default email;
