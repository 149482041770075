<template>
  <div>
    <!-- eslint-disable vue/no-mutating-props -->
    <v-dialog v-model="addSpeciesDialog" width="500" persistent>
      <v-card>
        <header-view
          dialog
          previous_page="NO_BACK_BUTTON"
          title="Add a Species"
          closeBtn
          :closeAction="
            () => {
              $emit('close');
              clearNewSpecies();
            }
          "
          :btnOptions="[
            {
              name: '',
              btnColor: 'white',
              icon: 'mdi-check',
              action: this.handleAddFormSubmit,
            },
          ]" />
        <span class="headline"></span>
        <v-spacer></v-spacer>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="form" id="addSpeciesForm">
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12>
                  <v-autocomplete
                    label="Region"
                    :items="speciesRegions.slice(1)"
                    v-if="admin"
                    item-text="text"
                    item-value="value"
                    v-model="addRegion"
                    multiple
                    chips
                    clearable></v-autocomplete>
                  <v-combobox
                    :items="addSpeciesAutoCompleteList"
                    v-model="newSpecies.common_name"
                    label="Common Name"
                    item-text="common_name"
                    item-value="common_name"
                    :return-object="false"
                    @input="onAddSpeciesInput('common_name', $event)"
                    :rules="validate_common_name"
                    clearable
                    @click:clear="clearNewSpecies"></v-combobox>
                  <v-combobox
                    :items="addSpeciesAutoCompleteList"
                    v-model="newSpecies.latin_name"
                    label="Latin Name"
                    item-text="latin_name"
                    item-value="latin_name"
                    :return-object="false"
                    :rules="validate_latin_name"
                    @input="onAddSpeciesInput('latin_name', $event)"
                    clearable
                    @click:clear="clearNewSpecies"></v-combobox>
                  <v-switch
                    v-model="addVerified"
                    color="button-primary"
                    label="Upload as Verified"
                    v-if="admin"></v-switch>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>

      <!--confirmation dialog-->
      <v-dialog v-model="addConfirmDialog" width="500" persistent>
        <v-card>
          <header-view
            dialog
            previous_page="NO_BACK_BUTTON"
            title="Add This Species?"
            closeBtn
            :closeAction="
              () => {
                addConfirmDialog = false;
              }
            "
            :btnOptions="[
              {
                name: '',
                btnColor: 'white',
                icon: 'mdi-check',
                action: this.saveNewSpecies,
              },
            ]" />
          <v-card-text>
            <v-container>
              <v-layout row>
                <v-flex md4 class="text-xs-right pr-2">
                  <b>Common Name</b>
                </v-flex>
                <v-flex md6>
                  {{ newSpecies.common_name }}
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex md4 class="text-xs-right pr-2">
                  <b>Latin Name</b>
                </v-flex>
                <v-flex md6>
                  {{ newSpecies.latin_name }}
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!--end confirmation-->
    </v-dialog>
    <!-- eslint-enable -->

    <add-species-status
      :show="showStatusDialog"
      :info="statusInfo"
      :loaded="speciesLoaded"
      @close="
        showStatusDialog = false;
        speciesLoaded = false;
        statusInfo = {};
      "></add-species-status>
  </div>
</template>

<script>
  import axios from 'axios';
  import SpeciesStatus from '@/components/species/add-species-status';
  import Species from '@/mixins/species';
  import HeaderView from '@/components/header-view';

  export default {
    name: 'AddSingleSpecies',
    components: {
      'add-species-status': SpeciesStatus,
      'header-view': HeaderView,
    },
    mixins: [Species],
    props: {
      admin: {
        default: false,
        type: Boolean,
      },
      speciesList: {
        default: () => [],
        type: Array,
      },
      addSpeciesDialog: {
        default: false,
        type: Boolean,
      },
    },
    data() {
      return {
        newSpecies: {
          common_name: '',
          latin_name: '',
          uuid: '',
        },
        addConfirmDialog: false,
        addSpeciesAutoCompleteList: [],
        validate_common_name: [(v) => !!v || 'This is required'],
        validate_latin_name: [
          (v) => !!v || 'This is required',
          (v) => {
            if (this.speciesList && v) {
              return (
                !this.speciesList.filter(
                  (species) =>
                    species.latin_name.toLowerCase() === v.toLowerCase()
                ).length > 0 || 'This latin name already exists in your list'
              );
            } else {
              return true;
            }
          },
        ],

        addVerified: true,
        addRegion: [],
        showStatusDialog: false,
        statusInfo: {},
        speciesLoaded: false,
      };
    },
    mounted() {
      this.validate_latin_name.push();
    },
    methods: {
      async startAddNewSpecies() {
        this.$emit('close');
        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();
        let url = '/service_tenants/master_species/';
        url += this.admin ? 'master' : 'verifiedMaster';
        axios({
          method: 'GET',
          url: url,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }).then((response) => {
          if (response) {
            this.addSpeciesAutoCompleteList = response.data.filter(
              (masterSpecies) => {
                var duplicate = false;
                //could be optimized to break early since speciesList should be sorted
                for (var i = 0; i < this.speciesList.length; i++) {
                  if (
                    this.speciesList[i].latin_name.toLowerCase() ==
                    masterSpecies.latin_name.toLowerCase()
                  ) {
                    duplicate = true;
                    break;
                  }
                }
                return !duplicate;
              }
            );
          }
        });
      },
      handleAddFormSubmit: function () {
        //delayed so inputs are updated before validation occurs
        setTimeout(() => {
          if (!this.$refs.form.validate()) {
            console.log('validate false');
            return false;
          }
          this.addConfirmDialog = true;
        }, 100);
      },
      saveNewSpecies: function () {
        this.$emit('close');
        this.addConfirmDialog = false;
        this.showStatusDialog = true;
        var newAddSpecies = {
          common_name: this.newSpecies.common_name,
          latin_name: this.newSpecies.latin_name,
          uuid: this.newSpecies.uuid,
        };
        if (this.admin) {
          var regions = this.addRegion
            ? this.addRegion.map((r) => r.value)
            : [];
          this.addMasterSpecies(
            [newAddSpecies],
            this.addVerified,
            regions
          ).then((response) => {
            this.statusInfo = response;
          });
        } else {
          this.addTenantSpecies([newAddSpecies]).then((response) => {
            //hacky fix to status showing 'added 2' instead of 'added 1'
            response.addedSpecies = response.addedSpecies.slice(0, 1);
            this.statusInfo = response;
          });
        }
        this.speciesLoaded = true;
        this.addSpeciesAutoCompleteList = [];
        this.clearNewSpecies();
        setTimeout(() => this.$emit('species-added'), 1000);
      },
      //autocomplete other field if species exists in master list
      onAddSpeciesInput: function (changedName, text) {
        // console.log( 'input' )
        var effectedName =
          changedName == 'common_name' ? 'latin_name' : 'common_name';
        if (text) {
          var foundSpecies = this.addSpeciesAutoCompleteList.find((species) => {
            return species[changedName].toLowerCase() == text.toLowerCase();
          });
          if (foundSpecies) {
            this.newSpecies[effectedName] = foundSpecies[effectedName];
            this.newSpecies.uuid = foundSpecies.uuid;
          } else if (changedName == 'latin_name') {
            this.newSpecies.uuid = '';
          }
        } else if (changedName == 'latin_name') {
          this.newSpecies.uuid = '';
        }
      },
      clearNewSpecies: function () {
        var x = this.newSpecies;
        x.common_name = x.latin_name = x.uuid = '';
      },
    },
  };
</script>
