<!-- ----------------------------------------------------------------------- -->
<!--
name    : INVENTORY MAP

type    : component

used by : AppClientView,
          AppEstimateProposal,
          AppEstimateView,
          JobSiteView,
          MyForest,
          MyForestJobSites,
          MyForestTrees,
          AppWorkorderView

uses    : work-task-list,
          work-task-history,
          simple-dialog-template,
          AddSpeciesSingle,
          header-view

route   : none
 -->
<!-- ----------------------------------------------------------------------- -->
<template>
  <div id="mapWrapper" class="inventory-map">
    <div id="mapDiv" />
    <v-snackbar v-model="savedProperlyDialog" :timeout="5000">
      {{ snackbarMessage || 'Tree saved successfully, thank you.' }}
      <v-btn color="blue" text @click="savedProperlyDialog = false">
        Close
      </v-btn>
    </v-snackbar>

    <v-snackbar v-model="undoMoveTreeSnackbar" :timeout="10000">
      Tree moved to new location.
      <v-btn color="blue" text @click="undoTreeMove"> Undo </v-btn>
    </v-snackbar>

    <v-dialog
      content-class="inventory-map-work-history-dialog"
      v-model="workHistoryDialog"
      width="700"
      persistent
      scrollable>
      <v-card
        class="work-task-history"
        color="grey lighten-4"
        min-width="350px"
        flat>
        <header-view
          dialog
          previous_page="NO_BACK_BUTTON"
          title="All Work Tasks for this Tree"
          closeBtn
          :closeAction="
            () => {
              workHistoryDialog = false;
            }
          " />
        <work-task-history
          :tree_uuid="currentTreeDetails.uuid"
          :tenantSettings="tenantSettings"
          tab="open" />
      </v-card>
    </v-dialog>

    <v-dialog
      content-class="inventory-map-add-tree-dialog"
      v-model="addTreeDialog"
      width="700"
      persistent
      scrollable>
      <v-card color="grey lighten-4" min-width="350px" flat>
        <header-view
          dialog
          previous_page="NO_BACK_BUTTON"
          title="Add a Tree"
          closeBtn
          :closeAction="
            () => {
              newTree = {};
              addTreeDialog = false;
            }
          "
          :btnOptions="treeAddButtons" />
        <v-card-text>
          <v-container>
            <v-row>
              <add-species-single
                :addSpeciesDialog="addSingleDialog"
                :speciesList="speciesList"
                @species-added="loadTenantSpecies"
                @close="addSingleDialog = false" />
              <v-col cols="6">
                <v-autocomplete
                  class="ml-n3"
                  :items="speciesList"
                  v-model="newTree.species"
                  label="Common Name"
                  item-text="common_name"
                  return-object
                  clearable
                  required
                  tags
                  ref="common_select"
                  @focus="sortSpecies('common_name')">
                  <template v-slot:no-data>
                    <v-btn
                      class="ml-3"
                      text
                      color="primary"
                      @click="addSingleSpecies">
                      Add a New Species
                    </v-btn>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="6">
                <v-autocomplete
                  :items="speciesList"
                  v-model="newTree.species"
                  label="Latin Name"
                  item-text="latin_name"
                  return-object
                  clearable
                  required
                  tags
                  ref="latin_select"
                  @focus="sortSpecies('latin_name')">
                  <template v-slot:no-data>
                    <v-btn
                      class="ml-3"
                      color="primary"
                      text
                      label="Add new species"
                      @click="addSingleSpecies">
                      Add a New Species
                    </v-btn>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-row>
                  <v-text-field
                    v-model.number="newTree.dbh"
                    label="DBH"
                    :suffix="
                      getUnits('dbh', tenantSettings.units_of_measurement)
                    "
                    :rules="validate_dbh"
                    type="number" />
                </v-row>
                <v-row>
                  <v-text-field
                    v-model="newTree.height"
                    label="Height"
                    :suffix="
                      getUnits('height', tenantSettings.units_of_measurement)
                    "
                    :rules="validate_height"
                    type="number" />
                </v-row>
                <v-row>
                  <v-select
                    v-model="newTree.condition"
                    label="Condition"
                    :items="condition_values" />
                </v-row>
              </v-col>
              <v-col cols="6">
                <v-textarea
                  outlined
                  rows="9"
                  v-model="newTree.notes"
                  label="Notes" />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- this is the view tree dialog. We need to set the 'eager' props api
   because this will pre-load the inner content without displaying it. We
   need this so it pre-loads the work-task-list component and there is no
   delay in displaying the associated worktasks for a tree -->
    <v-dialog
      content-class="inventory-map-view-tree-dialog"
      v-model="viewTreeDialog"
      width="800"
      eager
      persistent
      scrollable>
      <v-card
        content-class="view-tree-card"
        color="grey lighten-4"
        min-width="350px"
        flat>
        <header-view
          dialog
          previous_page="NO_BACK_BUTTON"
          title="Tree Details"
          closeBtn
          :closeAction="
            () => {
              viewTreeDialog = false;
            }
          "
          :btnOptions="setTreeDetailButtons()"
          ref="headerView" />
        <v-card-text content-class="view-tree-text">
          <v-container grid-list-md>
            <v-layout row>
              <v-flex xs12 md6>
                <v-layout row>
                  <v-flex xs12 md6 class="pr-2">
                    <b>Common Name</b>
                  </v-flex>
                  <v-flex xs12 md6>
                    {{ currentTreeCommonName }}
                  </v-flex>
                </v-layout>
                <v-layout row>
                  <v-flex xs12 md6 class="pr-2">
                    <b>Latin Name</b>
                  </v-flex>
                  <v-flex xs12 md6>
                    {{ currentTreeLatinName }}
                  </v-flex>
                </v-layout>
                <v-layout row>
                  <v-flex xs12 md6 class="pr-2">
                    <b>DBH</b>
                  </v-flex>
                  <v-flex xs12 md6>
                    {{
                      formatDBH(
                        currentTreeDetails.dbh,
                        tenantSettings.units_of_measurement
                      )
                    }}
                  </v-flex>
                </v-layout>
                <v-layout row>
                  <v-flex xs12 md6 class="pr-2">
                    <b>Height</b>
                  </v-flex>
                  <v-flex xs12 md6>
                    {{
                      formatHeight(
                        currentTreeDetails.height,
                        tenantSettings.units_of_measurement
                      )
                    }}
                  </v-flex>
                </v-layout>
                <v-layout row>
                  <v-flex xs12 md6 class="pr-2">
                    <b>Condition</b>
                  </v-flex>
                  <v-flex xs12 md6>
                    {{ currentTreeDetails.condition }}
                  </v-flex>
                </v-layout>
                <v-layout row>
                  <v-flex xs12 md6 class="pr-2">
                    <b>Notes</b>
                  </v-flex>
                  <v-flex xs12 md6>
                    {{ currentTreeDetails.notes }}
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 md6>
                <v-layout
                  row
                  wrap
                  class="thumbnailRow"
                  v-if="currentTreePhotos && currentTreePhotos.length > 0">
                  <v-flex
                    xs12
                    md6
                    v-for="photo in currentTreePhotos"
                    v-bind:key="photo.url">
                    <v-img
                      v-if="photo.url"
                      :src="photo.url"
                      class="pa-2 ma-3"
                      @click="
                        viewTreePhotoDetails(currentTreeDetails, photo, false)
                      " />
                  </v-flex>
                </v-layout>
                <v-layout row wrap v-else>
                  <v-flex xs12>
                    <div class="body-1">
                      <i>No photos uploaded yet.</i>
                    </div>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>

            <!-- This is the worktask list that comes up when clicking on a
            tree in estimate view and workorder view. Estimate must be passed
            in for both cases -->
            <span class="work-task-list" v-if="estimate">
              <work-task-list-inventory-map
                :readonly="estimateLocked"
                :enable_catalogs="!estimateLocked"
                :enable_openbid="!estimateLocked"
                :enable_controls="!estimateLocked"
                v-bind:proposal="proposal"
                v-bind:estimate="estimate"
                v-bind:tree_uuid="currentTreeDetails.uuid"
                v-bind:dbh="currentTreeDetails.dbh"
                v-bind:height="currentTreeDetails.height"
                v-bind:trees="trees"
                v-bind:tenantSettings="tenantSettings"
                @onReload="onWorkTasksChanged" />
            </span>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      content-class="inventory-map-edit-tree-dialog"
      v-model="editTreeDialog"
      width="700"
      persistent
      scrollable>
      <v-card color="grey lighten-4" min-width="350px" flat>
        <header-view
          dialog
          previous_page="NO_BACK_BUTTON"
          title="Edit Tree"
          closeBtn
          :closeAction="editTreeCancel"
          :btnOptions="[
            {
              name: '',
              btnColor: 'white',
              icon: 'mdi-check',
              action: editTreeSave,
            },
          ]" />
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="6">
                <v-autocomplete
                  :items="speciesList"
                  v-model="currentTreeDetails.species"
                  label="Common Name"
                  item-text="common_name"
                  return-object
                  clearable
                  required
                  tags
                  ref="common_select"
                  @focus="sortSpecies('common_name')">
                  <template v-slot:no-data>
                    <v-btn
                      class="ml-3"
                      color="primary"
                      text
                      label="Add new species"
                      @click="addSingleSpecies">
                      Add a New Species
                    </v-btn>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="6">
                <v-autocomplete
                  :items="speciesList"
                  v-model="currentTreeDetails.species"
                  label="Latin Name"
                  item-text="latin_name"
                  return-object
                  clearable
                  required
                  tags
                  ref="latin_select"
                  @focus="sortSpecies('latin_name')">
                  <template v-slot:no-data>
                    <v-btn
                      class="ml-3"
                      color="primary"
                      text
                      label="Add new species"
                      @click="addSingleSpecies">
                      Add a New Species
                    </v-btn>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-row>
                  <v-text-field
                    v-model="currentTreeDetails.dbh"
                    label="DBH"
                    :rules="validate_dbh"
                    type="number" />
                </v-row>
                <v-row>
                  <v-text-field
                    v-model="currentTreeDetails.height"
                    label="Height"
                    :rules="validate_height"
                    type="number" />
                </v-row>
                <v-row>
                  <v-select
                    v-model="currentTreeDetails.condition"
                    label="Condition"
                    :items="condition_values" />
                </v-row>
              </v-col>
              <v-col cols="6">
                <v-textarea
                  outlined
                  rows="9"
                  v-model="currentTreeDetails.notes"
                  label="Notes" />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      content-class="inventory-map-tree-photo-dialog"
      v-model="treePhotoDialog"
      width="700"
      persistent>
      <v-card color="grey lighten-4" min-width="350px" flat>
        <v-toolbar color="info" flat dark>
          <v-toolbar-title>Manage Photos</v-toolbar-title>
          <v-spacer />
          <!-- max of two images per tree allowed -->
          <v-btn
            class="ma-2 mr-0"
            icon
            :disabled="currentTreePhotos && currentTreePhotos.length >= 2">
            <image-uploader
              :debug="1"
              :maxWidth="512"
              :quality="0.7"
              :autoRotate="true"
              outputFormat="blob"
              :preview="false"
              :className="[]"
              :capture="false"
              accept="image/*"
              doNotResize="['gif', 'svg']"
              @input="uploadTreePhoto">
              <label for="fileInput" slot="upload-label">
                <v-icon v-if="!hasImage"> mdi-camera </v-icon>
                <v-progress-circular indeterminate color="white" v-else />
              </label>
            </image-uploader>
          </v-btn>
          <v-btn class="ma-2 mr-0" icon @click="treePhotoDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container style="max-height: 80%; overflow: auto">
            <v-row v-if="currentTreePhotos && currentTreePhotos.length > 0">
              <v-col
                cols="6"
                v-for="photo in currentTreePhotos"
                v-bind:key="photo.url">
                <v-img
                  v-if="photo && photo.url"
                  :src="photo.url"
                  @click="
                    viewTreePhotoDetails(currentTreeDetails, photo, true)
                  " />
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col>
                <div class="body-1">
                  <i>No photos uploaded yet.</i>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      content-class="inventory-map-confirm-delete-dialog"
      v-model="confirmDeletePhotoDialog"
      width="700"
      persistent>
      <v-card color="grey lighten-4" min-width="350px" flat>
        <header-view
          dialog
          previous_page="NO_BACK_BUTTON"
          title="Confirm Photo Delete" />
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="6">
                <v-img :src="currentPhoto.url" />
              </v-col>
              <v-col cols="6">
                <div class="body-1">
                  Are you sure you'd like to delete this photo? This cannot be
                  undone.
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="button-primary"
            @click="confirmDeleteTreePhoto(currentPhoto)">
            Delete
          </v-btn>
          <v-btn
            color="button-secondary"
            @click="confirmDeletePhotoDialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      content-class="inventory-map-photo-details-dialog"
      v-model="photoDetailsDialog"
      width="700"
      persistent>
      <v-card color="grey lighten-4" min-width="350px" flat>
        <header-view
          dialog
          previous_page="NO_BACK_BUTTON"
          title="Photo Details"
          closeBtn
          :closeAction="closePhotoDetailsDialog"
          :btnOptions="photoDetailsButtons" />
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="6">
                <v-img :src="currentPhoto.url" />
              </v-col>
              <v-col cols="6">
                <div class="title">Date</div>
                <div
                  class="body-1 mb-2"
                  v-if="currentPhoto && currentPhoto.date">
                  {{ new Date(currentPhoto.date).toLocaleDateString() }}
                </div>
                <v-textarea
                  outlined
                  v-if="canEditPhotoNotes"
                  placeholder="Add Notes"
                  :value="newPhotoNotes"
                  @change="(value) => (newPhotoNotes = value)" />
                <div v-else>
                  <div class="title">Notes</div>
                  <div class="body-1">
                    {{ currentPhoto.notes || 'No notes available.' }}
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <simple-dialog-template
      class="inventory-map-tree-instructions-dialog-template"
      :open="addTreeInstructions"
      dialogTitle="Add a Tree"
      dialogText="Click on the map to add tree location and information."
      dialogButtonTwo="Thanks"
      @buttonTwo="addTreeInstructions = false" />

    <simple-dialog-template
      class="inventory-map-delete-tree-dialog-template"
      :open="deleteTreeDialog"
      dialogTitle="Delete Tree"
      dialogText="Are you sure you wish to delete this tree? All work tasks associated with this tree will no longer be assigned to a tree. This cannot be undone."
      dialogButtonOne="Delete"
      dialogButtonTwo="Cancel"
      @buttonOne="onDeleteTree"
      @buttonTwo="deleteTreeDialog = false" />

    <simple-dialog-template
      class="inventory-map-deactivate-tree-dialog-template"
      :open="deactivateTreeDialog"
      dialogTitle="Deactivate Tree"
      dialogText="Are you sure you wish to deactive this tree? You will not be able to add additional work tasks to this tree. This cannot be undone."
      dialogButtonOne="Deactivate"
      dialogButtonTwo="Cancel"
      @buttonOne="onDeactivateTree"
      @buttonTwo="deactivateTreeDialog = false" />
  </div>
</template>

<script>
  // scripts
  import gmapsInit from '@/js/gmaps';
  import TreeOverlayFactory from '@/js/tree-overlay';

  // components
  import WorkTaskListInventoryMap from '@/components/worktasks/work-task-list-inventory-map';
  import WorkTaskHistory from '@/components/worktasks/work-task-history';
  import SimpleDialogTemplate from '@/components/simple-dialog-template';
  import AddSpeciesSingle from '@/components/species/add-species-single';
  import ImageUploader from 'vue-image-upload-resize';
  import HeaderView from '@/components/header-view';

  // mixins
  import addresses from '@/mixins/addresses.js';
  import Forms from '@/mixins/forms.js';
  import Localization from '@/mixins/localization';
  import dateTimeHelpers from '@/mixins/dateTimeHelperFunctions';

  // services
  import Clients from '@/services/Clients.service.js';
  import Tenants from '@/services/Tenants.service.js';
  import Jobs from '@/services/Jobs.service.js';

  export default {
    name: 'InventoryMap',
    components: {
      'work-task-list-inventory-map': WorkTaskListInventoryMap,
      'work-task-history': WorkTaskHistory,
      'simple-dialog-template': SimpleDialogTemplate,
      'add-species-single': AddSpeciesSingle,
      'image-uploader': ImageUploader,
      'header-view': HeaderView,
    },
    mixins: [addresses, Forms, dateTimeHelpers, Localization],
    props: {
      can_add_trees: {
        type: Boolean,
        default: false,
      },
      client: {
        type: Object,
      },
      estimate: {
        type: Object,
        default: () => {},
      },
      inventory_type: {
        type: String,
      },
      is_work_order: {
        type: Boolean,
        default: false,
      },
      jobsite: {
        type: Object,
      },
      jobsites: {
        type: Array,
      },
      proposal: {
        type: Boolean,
      },
      readonly: {
        //block adding, editing trees, or manipulating work tasks associated to trees.
        type: Boolean,
        default: false,
      },
      selected_jobsite_uuid: {
        type: String,
      },
      trees: {
        type: Array,
      },
      tenantSettings: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        treeOverlayWindow: null,
        allowJobSites: false,
        workHistoryDialog: false,
        confirmDeletePhotoDialog: false,
        hasImage: false,
        canEditPhotoNotes: false,
        photoDetailsDialog: false,
        currentPhoto: {},
        newPhotoNotes: '',
        undoMoveTreeSnackbar: false,
        originalTreeLocation: null,
        lastTreeMoved: null,
        lastMarkerMoved: null,
        savedProperlyDialog: false,
        snackbarMessage: '',
        treePhotoDialog: false,
        fileLoading: false,
        rules: [
          (value) => {
            if (value && value.size) {
              return (
                value.size < 4000000 || 'Photo size should be less than 4 MB.'
              );
            } else {
              return true;
            }
          },
        ],

        addTreeDialog: false,
        viewTreeDialog: false,
        editTreeDialog: false,
        deleteTreeDialog: false,

        deactivateTreeDialog: false,

        //for adding a species to tenant list
        addSingleDialog: false,

        addTreeInstructions: false,
        newTreeValid: true,
        newTree: {},
        lastAddedTree: {},
        map: undefined,
        geocoder: undefined,
        address: '',
        city: '',
        state: '',
        uuid: '',
        addTrees: this.can_add_trees,
        totalTreesCount: 0,
        currentTreeDetails: {},
        originalTreeDetails: {}, //tlc: saves the original values of a tree being edited, to restore if the edit is canceled
        speciesList: [],
        workTasks: [],
        allCurrentJobSiteMarkers: [],
        allInfoWindows: [],
        allCurrentTreeMarkers: [],
        legendIcons: {
          estimatePhase: {
            name: 'Estimate Phase',
            icon: require('../assets/myForestIcons/square-orange.png'),
          },
          workPhase: {
            name: 'Work Phase',
            icon: require('../assets/myForestIcons/square-green.png'),
          },
          multiplePhases: {
            name: 'Multiple Jobs',
            icon: require('../assets/myForestIcons/square-red.png'),
          },
          noWorkScheduled: {
            name: 'No Work Scheduled',
            icon: require('../assets/myForestIcons/square-gray.png'),
          },
          residence: {
            name: 'Residence',
            icon: require('../assets/myForestIcons/residence-legend.png'),
          },
          business: {
            name: 'Business',
            icon: require('../assets/myForestIcons/business-legend.png'),
          },
        },
        treeIcons: {
          default: {
            name: 'Default',
            icon: require('../assets/myForestIcons/tree-blue.png'),
          },
          completed: {
            name: 'Completed',
            icon: require('../assets/myForestIcons/tree-green.png'),
          },
          not_completed: {
            name: 'Incomplete Tasks',
            icon: require('../assets/myForestIcons/tree-red.png'),
          },
          none: {
            name: 'No Tasks',
            icon: require('../assets/myForestIcons/tree-gray.png'),
          },
        },
        treeLegendIcons: {
          completed: {
            workorder: 'Complete',
            estimate: 'Work Assigned',
            icon: require('../assets/myForestIcons/square-green.png'),
          },
          not_completed: {
            workorder: 'Incomplete',
            // "estimate": "--", // absense of this key causes it not to be drawn for estimate legends
            icon: require('../assets/myForestIcons/square-red.png'),
          },
          none: {
            workorder: 'No Tasks',
            estimate: 'No Work Assigned',
            icon: require('../assets/myForestIcons/square-gray.png'),
          },
        },
        houseURLs: {
          'Estimate Phase': {
            icon: require('../assets/myForestIcons/residence-orange.png'),
          },
          'Work Phase': {
            icon: require('../assets/myForestIcons/residence-green.png'),
          },
          'No Work Scheduled': {
            icon: require('../assets/myForestIcons/residence-gray.png'),
          },
          'Multiple Jobs': {
            icon: require('../assets/myForestIcons/residence-red.png'),
          },
        },
        buildingURLs: {
          'Estimate Phase': {
            icon: require('../assets/myForestIcons/business-orange.png'),
          },
          'Work Phase': {
            icon: require('../assets/myForestIcons/business-green.png'),
          },
          'No Work Scheduled': {
            icon: require('../assets/myForestIcons/business-gray.png'),
          },
          'Multiple Jobs': {
            icon: require('../assets/myForestIcons/business-red.png'),
          },
        },
        clientConfigurations: {},
      };
    },
    /*
  currentTreeDetails is an object for any one tree in view/edit mode, from the trees array, for the purpose of binding to visible forms
  */
    computed: {
      photoDetailsButtons() {
        var btns = [];

        if (this.canEditPhotoNotes) {
          btns.push({
            name: '',
            btnColor: 'white',
            icon: 'mdi-check',
            action: this.savePhotoDetails,
          });

          if (!this.readonly) {
            btns.push({
              name: '',
              btnColor: 'white',
              icon: 'mdi-delete',
              action: () => {
                this.deleteTreePhoto(this.currentPhoto);
              },
            });
          }
        }

        return btns;
      },
      currentTreePhotos: function () {
        if (this.currentTreeDetails.photos) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          return this.currentTreeDetails.photos.reverse();
        } else {
          return [];
        }
      },
      currentTreeCommonName: function () {
        return this.currentTreeDetails.species
          ? this.currentTreeDetails.species.common_name
          : '';
      },
      currentTreeLatinName: function () {
        return this.currentTreeDetails.species
          ? this.currentTreeDetails.species.latin_name
          : '';
      },
      worktask_filter: function () {
        var filter = {};
        if (this.client && this.client.tenant_uuid) {
          filter.tenant_uuid = this.client.tenant_uuid;
          filter.client_uuid = this.client.uuid;
        }
        if (this.estimate && this.estimate.tenant_uuid) {
          filter.job_uuid = this.estimate.job_uuid;
          filter.estimate_uuid = this.estimate.uuid;
        }
        if (this.currentTreeDetails && this.currentTreeDetails.uuid) {
          filter.tree_uuid = this.currentTreeDetails.uuid;
        }
        return filter;
      },
      treeAddButtons: function () {
        var btns = [];
        if (this.lastAddedTree && Object.keys(this.lastAddedTree).length > 0) {
          btns.push({
            name: '',
            btnColor: 'white',
            icon: 'mdi-rotate-right',
            action: this.loadLastTree,
          });
        }
        btns.push({
          name: '',
          btnColor: 'white',
          icon: 'mdi-check',
          action: this.addNewTree,
        });
        return btns;
      },
      estimateLocked: function () {
        return (
          (this.estimate &&
            (this.estimate.status == 'Approved' ||
              this.estimate.status == 'Sent')) ||
          this.readonly
        );
      },
    },
    watch: {
      jobsites: {
        immediate: true,
        handler() {
          if (this.jobsites && this.jobsites.length == 1) {
            this.address = this.jobsites[0].address;
            this.city = this.jobsites[0].city;
            this.state = this.jobsites[0].state;
            this.uuid = this.jobsites[0].uuid;
          }

          if (!window.google || !this.map || !this.geocoder) {
            return;
          }

          if (this.inventory_type == 'jobSites') {
            this.renderJobSitesMap();
          }
        },
      },
      trees: {
        handler(val, oldval) {
          // console.log("trees", this.trees);

          if (
            this.trees &&
            this.trees.length &&
            this.inventory_type == 'trees'
          ) {
            this.renderTreesMap(!oldval || oldval.length === 0);
          }
        },
      },
      inventory_type: function () {
        // console.log("inventory_type", this.inventory_type);

        if (this.inventory_type == 'trees') {
          this.renderTreesMap(true);
        } else {
          this.renderJobSitesMap(true);
        }
      },
      viewTreeDialog: function (shown) {
        if (shown) {
          this.setTreeDetailButtons();
        }
      },
    },
    async mounted() {
      // load client configs
    },
    async created() {
      // console.log("created inventory-map");
      // console.log("trees", this.trees);
      // console.log("jobsites", this.jobsites);
      // console.log("selected_jobsite_uuid", this.selected_jobsite_uuid);
      // console.log("can_add_trees", this.can_add_trees);
      // console.log("inventory_type", this.inventory_type);
      // console.log("client", this.client);
      // console.log("estimate", this.estimate);

      this.getAllowed();

      // set up clinet

      // map stuff
      this.clientConfigurations = this.$getConfig().client_configurations;

      await gmapsInit(this.clientConfigurations.google.maps_api_key);

      // console.log("finished gmapsInit");

      try {
        // console.log("inside try block");

        if (this.tenantSettings && this.tenantSettings.country) {
          // console.log("verified we have tenant settings");

          this.geocoder = new window.google.maps.Geocoder();

          this.map = new window.google.maps.Map(
            document.getElementById('mapDiv'),
            {
              mapTypeId: 'hybrid',
              zoom: this.mapDSettingsByCountry[this.tenantSettings.country]
                .zoom,
              center:
                this.mapDSettingsByCountry[this.tenantSettings.country].center,
            }
          );

          this.setTreeDetailButtons();

          if (this.can_add_trees && !this.readonly) {
            this.map.addListener('click', (e) => {
              if (this.treeOverlayWindow) {
                this.treeOverlayWindow.setMap(null);
                this.treeOverlayWindow = null;
              }
              this.addTreeDialog = true;
              this.newTree.latLng = e.latLng;
            });
          }

          // this is for the add tree drop down
          await this.loadTenantSpecies();

          if (this.inventory_type == 'trees') {
            this.renderTreesMap(true);
          }

          if (this.inventory_type == 'jobSites') {
            this.renderJobSitesMap();
          }
        }
      } catch (err) {
        console.log(err);
      }

      // this.viewTreeDialog = false;
    },
    methods: {
      async getAllowed() {
        this.allowJobSites = await this.$auth.isAllowed('jobSites', 'view');
      },
      async undoTreeMove() {
        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();

        this.undoMoveTreeSnackbar = false;

        let currentlocation = this.lastTreeMoved.location;
        this.lastTreeMoved.location = this.originalTreeLocation;

        var res = await Clients.updateTree(
          this.lastTreeMoved.uuid,
          this.lastTreeMoved,
          accessToken
        );

        if (res) {
          this.lastMarkerMoved.setPosition(
            // eslint-disable-next-line no-undef
            new google.maps.LatLng(
              parseFloat(this.lastTreeMoved.location[0]),
              parseFloat(this.lastTreeMoved.location[1])
            )
          );
        } else {
          this.snackbarMessage = 'Failed to undo moving the tree.';
          this.savedProperlyDialog = true;

          this.lastTreeMoved.location = currentlocation;
        }
      },
      setTreeDetailButtons: function () {
        // console.log("setTreeDetailButtons");

        var btns = [];
        if (!this.readonly) {
          btns.push({
            name: '',
            icon: 'mdi-folder-remove',
            btnColor: 'white',
            title: 'Deactivate Tree',
            loading: this.currentTreeDetails && this.currentTreeDetails.loading,
            show: this.canDeactivateTree(),
            action: () => {
              this.deactivateTreeDialog = true;
            },
          });
          btns.push({
            name: '',
            icon: 'mdi-text-box-multiple',
            btnColor: 'white',
            title: 'View Work History',
            action: () => {
              this.workHistoryDialog = true;
            },
          });
          btns.push({
            name: '',
            icon: 'mdi-image',
            btnColor: 'white',
            title: 'Add Photo',
            action: () => {
              this.treePhotoDialog = true;
            },
          });
          btns.push({
            name: '',
            icon: 'mdi-pencil',
            btnColor: 'white',
            title: 'Edit Tree',
            action: this.editTree,
          });
        }

        // if ( this.$refs.headerView ) {
        //   this.$refs.headerView.buttons = btns;
        // }

        return btns;
      },
      canDeactivateTree: function () {
        if (this.currentTreeDetails && this.currentTreeDetails.uuid) {
          return (
            !this.readonly &&
            !this.hasActiveWorkTasks() &&
            !this.currentTreeDetails.hasOpenWorkOrders
          );
        }
        return false;
      },
      onWorkTasksChanged: async function () {
        // console.log("onWorkTasksChanged");

        // show spinner if the ovrlay is open
        if (this.treeOverlayWindow) {
          this.treeOverlayWindow.showDeleteButtonSpinner();
        }

        // load work tasks which will be used to check if deactivate button should be shown
        if (this.currentTreeDetails && this.currentTreeDetails.uuid) {
          this.workTasks = await Jobs.getWorkTasksByParams({
            tree_uuid: this.currentTreeDetails.uuid,
          });
        }

        // tell overlay to check if it should show deactivate button
        this.setTreeDetailButtons();

        // hide spiner if overlay is open
        if (this.treeOverlayWindow) {
          this.treeOverlayWindow.setDeleteButtonVisiblity(
            !this.hasWorkTasks(),
            this.readonly
          );
        }
      },
      centerAndZoom: function (bounds) {
        //center the map to the geometric center of all markers
        this.map.setCenter(bounds.getCenter());

        this.map.fitBounds(bounds);

        //remove one zoom level to ensure no marker is on the edge.
        this.map.setZoom(this.map.getZoom() - 1);
      },
      clearAllMarkers: function () {
        // console.log("clearAllMarkers");
        this.clearJobSiteMarkers();
        this.clearTreeMarkers();
      },
      clearTreeMarkers: function () {
        // console.log("clearTreeMarkers");
        for (var i = 0; i < this.allCurrentTreeMarkers.length; i++) {
          this.allCurrentTreeMarkers[i].setMap(null);
        }
        this.allCurrentTreeMarkers = [];
      },
      clearJobSiteMarkers: function () {
        // console.log("clearJobSiteMarkers");
        for (var i = 0; i < this.allCurrentJobSiteMarkers.length; i++) {
          this.allCurrentJobSiteMarkers[i].setMap(null);
        }
        this.allCurrentJobSiteMarkers = [];
      },
      closePhotoDetailsDialog: function () {
        this.photoDetailsDialog = false;
        this.newPhotoNotes = undefined;
      },
      confirmDeleteTreePhoto: async function () {
        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();

        this.photoDetailsDialog = false;
        this.confirmDeletePhotoDialog = false;
        this.newPhotoNotes = undefined;

        var res = await Clients.deleteTreePhoto(
          this.currentTreeDetails.uuid,
          {
            photoId: this.currentPhoto.photoId,
          },
          accessToken
        );

        if (res) {
          this.currentTreeDetails.photos = res.photos;
        }
      },
      deleteTreePhoto: function () {
        this.confirmDeletePhotoDialog = true;
        this.newPhotoNotes = undefined;
      },
      async savePhotoDetails() {
        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();

        this.currentPhoto.notes = this.newPhotoNotes;
        this.newPhotoNotes = undefined;
        this.photoDetailsDialog = false;

        var res = await Clients.updateTreeNotes(
          this.currentTreeDetails.uuid,
          {
            notes: this.currentPhoto.notes,
            photoId: this.currentPhoto.photoId,
          },
          accessToken
        );

        if (res) {
          this.photoDetailsDialog = false;
        } else {
          console.log('could not update photo details');
        }
      },
      viewTreePhotoDetails(treeDetails, photo, canEdit) {
        this.currentPhoto = photo;
        this.photoDetailsDialog = true;
        this.canEditPhotoNotes = canEdit;
        if (canEdit) {
          this.newPhotoNotes = this.currentPhoto.notes;
        }
      },
      async uploadTreePhoto(file) {
        if (this.fileLoading) {
          console.log('file still loading...could not continue upload yet');
          return;
        }

        if (!this.currentTreeDetails.uuid) {
          console.log('was unable to get current tree uuid');
          return;
        }

        this.hasImage = true;
        this.fileLoading = true;

        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();

        // upload tree photo
        var res = await Clients.uploadTreePhoto(
          this.currentTreeDetails.uuid,
          file,
          accessToken
        );

        if (res) {
          this.hasImage = false;
          this.fileLoading = false;
          this.file = undefined;
          this.currentTreeDetails.photos = res.photos;
          this.$events.$emit('onTreesChanged');
        } else {
          console.log('could not upload tree photo!');
        }
      },
      updateTrees() {
        // console.log("updateTrees");

        if (this.trees && this.trees.length && this.inventory_type == 'trees') {
          for (var i = 0; i < this.allCurrentTreeMarkers.length; i++) {
            this.allCurrentTreeMarkers[i].setMap(null);
          }
          this.allCurrentTreeMarkers = [];
          this.renderTreesMap(true);
        }
      },
      openDialog: function () {
        this.viewTreeDialog = true;
      },
      async addNewTree() {
        // console.log("addNewTree");

        this.lastAddedTree = JSON.parse(JSON.stringify(this.newTree));
        this.newTree.uuid = Clients.newTreeUUID();

        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();

        // create new tree object
        var newTreedata = {
          uuid: this.newTree.uuid,
          job_site_number: this.totalTreesCount + 1,
          tenant_species_uuid: this.newTree.species.tenantSpeciesUUID,
          location: [this.newTree.latLng.lat(), this.newTree.latLng.lng()],
          dbh: this.newTree.dbh,
          height: this.newTree.height,
          condition: this.newTree.condition,
          notes: this.newTree.notes,
          job_site_uuid: this.uuid,
          client_uuid: this.client.uuid,
          tenant_uuid: this.client.tenant_uuid,
        };

        var res = await Clients.createTree(newTreedata, accessToken);

        if (res) {
          this.currentTreeDetails = res;
          //this.lastAddedTree = newTreeResult.data
          this.currentTreeDetails.species = this.speciesList.find((x) => {
            return (
              x.tenantSpeciesUUID == this.currentTreeDetails.tenant_species_uuid
            );
          });

          this.newTree = {};

          this.addTreeDialog = false;
          this.viewTreeDialog = true;
          this.savedProperlyDialog = true;
          this.totalTreesCount += 1;
          this.$events.$emit('onTreesChanged');
        } else {
          console.log('failed to create new tree');
          console.log('ERROR - Data was not saved');
          alert('ERROR - Data was not saved');
        }
      },
      editTree: function () {
        // console.log("editTree");

        //save the original values from the tree we are editing, so we can restore them if necessary
        this.originalTreeDetails.uuid = this.currentTreeDetails.uuid;
        this.originalTreeDetails.job_site_number =
          this.currentTreeDetails.job_site_number;
        this.originalTreeDetails.tenant_species_uuid =
          this.currentTreeDetails.tenant_species_uuid;
        this.originalTreeDetails.location = this.currentTreeDetails.location;
        this.originalTreeDetails.dbh = this.currentTreeDetails.dbh;
        this.originalTreeDetails.height = this.currentTreeDetails.height;
        this.originalTreeDetails.condition = this.currentTreeDetails.condition;
        this.originalTreeDetails.notes = this.currentTreeDetails.notes;
        this.originalTreeDetails.job_site_uuid =
          this.currentTreeDetails.job_site_uuid;
        this.originalTreeDetails.client_uuid =
          this.currentTreeDetails.client_uuid;

        // redundant - can be replaced with change to bindings/calcs
        this.originalTreeDetails.species = this.currentTreeDetails.species;

        this.editTreeDialog = true;
      },
      editTreeCancel: function () {
        // console.log("editTreeCancel");

        //restore original tree details
        this.currentTreeDetails.uuid = this.originalTreeDetails.uuid;
        this.currentTreeDetails.job_site_number =
          this.originalTreeDetails.job_site_number;
        this.currentTreeDetails.tenant_species_uuid =
          this.originalTreeDetails.tenant_species_uuid;
        this.currentTreeDetails.location = this.originalTreeDetails.location;
        this.currentTreeDetails.dbh = this.originalTreeDetails.dbh;
        this.currentTreeDetails.height = this.originalTreeDetails.height;
        this.currentTreeDetails.condition = this.originalTreeDetails.condition;
        this.currentTreeDetails.notes = this.originalTreeDetails.notes;
        this.currentTreeDetails.job_site_uuid =
          this.originalTreeDetails.job_site_uuid;
        this.currentTreeDetails.client_uuid =
          this.originalTreeDetails.client_uuid;

        // redundant - can be replaced with change to bindings/calcs
        this.currentTreeDetails.species = this.originalTreeDetails.species;

        //close tree edit dialog
        this.editTreeDialog = false;
      },
      async editTreeSave() {
        // console.log("editTreeSave");

        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();

        //get these back in sync
        this.currentTreeDetails.tenant_species_uuid =
          this.currentTreeDetails.species.tenantSpeciesUUID;

        var data = {
          uuid: this.currentTreeDetails.uuid,
          job_site_number: this.currentTreeDetails.job_site_number,
          tenant_species_uuid:
            this.currentTreeDetails.species.tenantSpeciesUUID,
          location: [
            this.currentTreeDetails.location[0],
            this.currentTreeDetails.location[1],
          ],
          dbh: this.currentTreeDetails.dbh,
          height: this.currentTreeDetails.height,
          condition: this.currentTreeDetails.condition,
          notes: this.currentTreeDetails.notes,
          job_site_uuid: this.currentTreeDetails.job_site_uuid,
          client_uuid: this.currentTreeDetails.client_uuid,
        };

        var res = await Clients.updateTree(
          this.currentTreeDetails.uuid,
          data,
          accessToken
        );

        if (res) {
          // console.log("editTreeSave - heres the new tree after POST");
          // console.log( newTreeResult.data )

          this.editTreeDialog = false;
          this.viewTreeDialog = true;
          this.savedProperlyDialog = true;

          // directly render map, or trigger watcher:
          // this.renderTreesMap()
          // eslint-disable-next-line vue/no-mutating-props
          this.trees.push({}); //trigger watcher
          // eslint-disable-next-line vue/no-mutating-props
          this.trees.pop();
        }
      },
      async onDeactivateTree() {
        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();

        var res = await Clients.updateTree(
          this.currentTreeDetails.uuid,
          {
            status: 'inactive',
          },
          accessToken
        );

        if (res) {
          this.deactivateTreeDialog = false;
          this.onRemoveTree('The tree has been deactivated');
        }
      },
      async onDeleteTree() {
        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();

        var res = await Clients.deleteTree(
          this.currentTreeDetails.uuid,
          accessToken
        );

        if (res) {
          this.deleteTreeDialog = false;
          this.onRemoveTree('The tree has been deleted');
        }
      },
      onRemoveTree: function (message) {
        //called by both deactive and delete to cleanup UI
        this.viewTreeDialog = false;
        this.currentTreeDetails = {};

        this.snackbarMessage = message;
        this.savedProperlyDialog = true;

        this.closeMapOverlays();
        this.$events.$emit('onTreesChanged');
      },
      savedProperlyDialogClose: function () {
        this.savedProperlyDialog = false;
        this.snackbarMessage = '';

        //this.$router.go(0)
      },
      addTreeInstructionsClose: function () {
        this.addTreeInstructions = false;
      },
      closeMapOverlays: function () {
        if (this.allInfoWindows && this.allInfoWindows.length) {
          this.allInfoWindows.forEach((window) => {
            window.close();
          });
        }

        if (this.treeOverlayWindow) {
          this.treeOverlayWindow.setMap(null);
          this.treeOverlayWindow = null;
        }
      },
      getTreeMarkerIcon: function (tree) {
        var url;

        if (tree.workTaskStatus) {
          if (tree.workTaskStatus == 'complete') {
            url = this.treeIcons['completed']['icon'];
          } else if (tree.workTaskStatus == 'not_complete') {
            url = this.treeIcons['not_completed']['icon'];
          } else {
            url = this.treeIcons['none']['icon'];
          }
        } else if (tree.workTaskCount >= 0) {
          if (tree.workTaskCount > 0) {
            url = this.treeIcons['completed']['icon'];
          } else {
            url = this.treeIcons['none']['icon'];
          }
        } else {
          url = this.treeIcons['default']['icon'];
        }
        return {
          url: url,
          scaledSize: new window.google.maps.Size(31, 47),
        };
      },
      drawTreesLegend: function () {
        if (!this.trees || this.trees.length == 0) {
          return;
        }

        var drawEstimateLegend = this.trees[0].workTaskCount >= 0;

        var iconNameIndex;
        if (this.is_work_order) {
          iconNameIndex = 'workorder';
        } else if (drawEstimateLegend) {
          iconNameIndex = 'estimate';
        } else {
          return; // dont draw the legned
        }

        // build the legend
        var legend = document.createElement('div');
        legend.id = 'legend';
        legend.style.background = '#fff';
        legend.style.padding = '10px';
        legend.style.margin = '10px';
        legend.style.border = '3px solid #000';
        legend.innerHTML =
          "<h3 style='marginTop:0; text-align:center;'>Tree Work Tasks</h3>";

        for (var key in this.treeLegendIcons) {
          var type = this.treeLegendIcons[key];
          var name = type[iconNameIndex];
          var icon = type.icon;

          // dont draw legend item if there is not a name associated
          if (!name) {
            continue;
          }

          var div = document.createElement('div');
          div.style.margin = '5px 0';
          div.innerHTML =
            '<img style="vertical-align: middle;width:20px;height:20px;margin:5px;" class="legendIcon" src="' +
            icon +
            '"> ' +
            name;
          legend.appendChild(div);
        }
        document.querySelector('#mapWrapper').appendChild(legend);
        this.map.controls[window.google.maps.ControlPosition.RIGHT_BOTTOM].push(
          legend
        );
      },
      async renderTreesMap(pan) {
        // console.log("renderTreesMap");

        this.clearAllMarkers();

        if (
          this.map &&
          this.map.controls[window.google.maps.ControlPosition.RIGHT_BOTTOM] &&
          this.map.controls[window.google.maps.ControlPosition.RIGHT_BOTTOM]
            .length
        ) {
          this.map.controls[
            window.google.maps.ControlPosition.RIGHT_BOTTOM
          ].pop();
        }

        // this event is generated by v-cards in, eg, AppClientView.vue
        this.$events.$off('treeMarkerSelect');

        this.$events.$on('treeMarkerSelect', (treeId) => {
          window.google.maps.event.trigger(marker, 'closeInfo');
          this.allCurrentTreeMarkers.forEach((marker) => {
            // console.log( 'treeMarkerSelect event listener' )
            if (marker.treeId == treeId) {
              window.google.maps.event.trigger(marker, 'click');
            }
          });
        });

        var marker;
        var allCurrentTreeMarkers = this.allCurrentTreeMarkers;

        this.closeMapOverlays();

        this.totalTreesCount = this.trees ? this.trees.length : 0;

        if (this.trees) {
          this.trees.forEach((singleTree) => {
            // console.log( singleTree )

            if (singleTree.status == 'inactive') {
              return;
            }

            var markerOptions = {
              map: this.map,
              draggable: true,
              position: {
                lat: parseFloat(singleTree.location[0]),
                lng: parseFloat(singleTree.location[1]),
              },
              icon: this.getTreeMarkerIcon(singleTree),
            };

            marker = new window.google.maps.Marker(markerOptions);
            marker.treeId = singleTree.uuid;
            allCurrentTreeMarkers.push(marker);

            window.google.maps.event.addListener(
              marker,
              'dragstart',
              (function (marker, self) {
                return function () {
                  marker.setOpacity(0.5);
                  self.undoMoveTreeSnackbar = false;

                  if (self.treeOverlayWindow) {
                    self.treeOverlayWindow.setMap(null);
                    self.treeOverlayWindow = null;
                    self.currentTreeDetails = {};
                  }
                };
              })(marker, this)
            );

            window.google.maps.event.addListener(
              marker,
              'dragend',
              (function (marker, self, singleTree) {
                return async function () {
                  marker.setOpacity(1);

                  if (
                    marker.position.lat != parseFloat(singleTree.location[0]) ||
                    marker.position.lng != parseFloat(singleTree.location[1])
                  ) {
                    // set up variables to undo move
                    self.originalTreeLocation = singleTree.location;
                    self.lastTreeMoved = singleTree;
                    self.lastMarkerMoved = marker;

                    let newLocation = marker.getPosition();
                    singleTree.location = [
                      newLocation.lat(),
                      newLocation.lng(),
                    ];

                    // Get the access token from the auth wrapper
                    const accessToken = await self.$auth.getTokenSilently();

                    var res = await Clients.updateTree(
                      singleTree.uuid,
                      {
                        location: singleTree.location,
                      },
                      accessToken
                    );

                    if (res) {
                      self.undoMoveTreeSnackbar = true;
                    } else {
                      // on failure, move the marker back to original location
                      singleTree.location = self.originalTreeLocation;

                      marker.setPosition(
                        // eslint-disable-next-line no-undef
                        new google.maps.LatLng(
                          parseFloat(singleTree.location[0]),
                          parseFloat(singleTree.location[1])
                        )
                      );
                    }
                  }
                };
              })(marker, this, singleTree)
            );

            window.google.maps.event.addListener(
              marker,
              'click',
              (function (marker, self, singleTree) {
                // console.log('CLICK! readonly: ', self.readonly);

                return async function () {
                  //set the 'current' tree to this one, to bind the view/edit forms
                  self.currentTreeDetails = singleTree;
                  self.currentTreeDetails.loading = true;

                  // unsecured work task pull (needed for estimate approval)
                  var workTasks = await Jobs.getWorkTasksByParams({
                    tree_uuid: self.currentTreeDetails.uuid,
                  });

                  // console.log( "currentTreeDetails: ", self.currentTreeDetails, self.speciesList )

                  self.currentTreeDetails.species = self.speciesList.find(
                    (x) => {
                      return (
                        x.tenantSpeciesUUID ==
                        self.currentTreeDetails.tenant_species_uuid
                      );
                    }
                  );

                  // close other tree overlay if it is open
                  if (self.treeOverlayWindow) {
                    self.treeOverlayWindow.setMap(null);
                    self.treeOverlayWindow = null;
                  }

                  var treeOverlayWindowEvents = {
                    onTreeDetails: function () {
                      self.viewTreeDialog = true;
                    },
                    onClose: function () {
                      self.treeOverlayWindow.setMap(null);
                      self.treeOverlayWindow = null;
                      self.currentTreeDetails = {};
                    },
                    onDelete: function () {
                      self.deleteTreeDialog = true;
                    },
                  };

                  var treeLocation = {
                    lat: parseFloat(singleTree.location[0]),
                    lng: parseFloat(singleTree.location[1]),
                  };

                  var instancething = TreeOverlayFactory();
                  self.treeOverlayWindow = await new instancething(
                    self.currentTreeDetails,
                    self.map,
                    treeLocation,
                    treeOverlayWindowEvents,
                    self.getUnitsOfMeasurment(
                      self.tenantSettings.units_of_measurement
                    )
                  );

                  // check for open workorders on tree (not for readonly)
                  if (!self.readonly) {
                    // Get the access token from the auth wrapper
                    const accessToken = await self.$auth.getTokenSilently();
                    var treeIsPartOfOpenWorkOrders =
                      await Jobs.isTreePartOfOpenWorkOrders(
                        self.currentTreeDetails.uuid,
                        accessToken
                      );
                    if (treeIsPartOfOpenWorkOrders) {
                      self.currentTreeDetails.hasOpenWorkOrders = true;
                    }
                  }

                  if (workTasks && self.treeOverlayWindow) {
                    // console.log(workTasks);
                    self.currentTreeDetails.loading = false;
                    self.workTasks = workTasks;
                    self.treeOverlayWindow.setDeleteButtonVisiblity(
                      !self.hasWorkTasks(),
                      self.readonly
                    );
                  }
                };
              })(marker, this, singleTree)
            );
          });
        }

        if (allCurrentTreeMarkers && allCurrentTreeMarkers.length && pan) {
          var markers = allCurrentTreeMarkers;
          var bounds = new window.google.maps.LatLngBounds();
          for (var i = 0; i < markers.length; i++) {
            bounds.extend(markers[i].getPosition());
          }

          // zoom, will only work if map is already loaded
          this.centerAndZoom(bounds);

          // wait for map to be ready, then zoom (sometimes map isnt loaded on first try)
          window.google.maps.event.addListenerOnce(
            this.map,
            'idle',
            function () {
              this.centerAndZoom(bounds);
            }.bind(this)
          );
        } else if (pan) {
          if (
            (this.selected_jobsite_uuid && this.jobsites) ||
            (this.jobsites &&
              this.jobsites.length == 1 &&
              this.jobsites[0].city &&
              this.jobsites[0].state)
          ) {
            let jobsite;
            if (this.selected_jobsite_uuid) {
              jobsite = this.jobsites.filter(
                function (site) {
                  return site.uuid == this.selected_jobsite_uuid;
                }.bind(this)
              )[0];
            } else {
              jobsite = this.jobsites[0];
            }

            var localMap = this.map;
            var address = this.addressToString(jobsite);

            this.geocoder.geocode(
              {
                address: address,
              },
              function (results) {
                if (results && results[0]) {
                  localMap.setCenter(results[0].geometry.location);
                  localMap.fitBounds(results[0].geometry.viewport);
                }
              }
            );
          }
        }
        if (this.selected_jobsite_uuid || this.estimate || this.is_work_order) {
          this.showCurrentJobSitePin();
        }

        this.drawTreesLegend();
      },
      hasActiveWorkTasks: function () {
        for (var i = 0; i < this.workTasks.length; i++) {
          if (this.workTasks[i] == 'not_complete') {
            return true;
          }
        }
        return false;
      },
      hasWorkTasks: function () {
        if (this.workTasks && this.workTasks.length > 0) {
          return true;
        } else {
          return false;
        }
      },
      renderJobSitesMap: function () {
        // console.log("renderJobSitesMap");

        this.clearAllMarkers();

        if (
          this.map &&
          this.map.controls[window.google.maps.ControlPosition.RIGHT_BOTTOM] &&
          this.map.controls[window.google.maps.ControlPosition.RIGHT_BOTTOM]
            .length
        ) {
          this.map.controls[
            window.google.maps.ControlPosition.RIGHT_BOTTOM
          ].pop();
        }

        this.$events.$on('jobSiteMarkerSelect', (jobSiteId) => {
          this.allCurrentJobSiteMarkers.forEach((marker) => {
            window.google.maps.event.trigger(marker, 'closeInfo');
            if (marker.jobSiteId == jobSiteId) {
              window.google.maps.event.trigger(marker, 'click');
            }
          });
        });

        this.closeMapOverlays();

        var localMap;

        if (this.jobsites) {
          var marker;
          var jobsites = this.jobsites;
          var jobSiteId;
          var allInfoWindows = this.allInfoWindows;
          localMap = this.map;

          for (var i = 0; i < this.jobsites.length; i++) {
            var address =
              this.jobsites[i].address +
              ', ' +
              this.jobsites[i].city +
              ', ' +
              this.jobsites[i].state;
            var contentString = 'Undetermined';
            var infowindow;
            var markerOptions = {};
            var self = this;

            this.geocoder.geocode(
              {
                address: address,
              },
              function (results) {
                if (
                  results &&
                  results[0] &&
                  self.inventory_type == 'jobSites'
                ) {
                  for (var j = 0; j < jobsites.length; j++) {
                    //this logic is matching the street number of the job sites, so
                    // if theres two different job sites with the same street number,
                    // this could be a problem. the street names would require a more
                    // flexible matching function than just index of
                    var resultsStreetNumber = '';
                    for (
                      var k = 0;
                      k < results[0].address_components.length;
                      k++
                    ) {
                      if (
                        results[0].address_components[k].types[0] ==
                        'street_number'
                      ) {
                        resultsStreetNumber =
                          results[0].address_components[k].short_name;
                      }
                    }
                    if (jobsites[j].address.indexOf(resultsStreetNumber) > -1) {
                      var currentAddress =
                        jobsites[j].address +
                        ', ' +
                        jobsites[j].city +
                        ', ' +
                        jobsites[j].state;

                      jobSiteId = jobsites[j].uuid;

                      var infoWindowHeader = jobsites[j].name || 'No Site Name';

                      if (self.allowJobSites) {
                        contentString =
                          '<div class="content">' +
                          '<h1 class="firstHeading">' +
                          infoWindowHeader +
                          '</h1>' +
                          '<div id="bodyContent">' +
                          '<h4 style="margin:5px 0;">' +
                          currentAddress +
                          '</h4>' +
                          '<a href="/clients/view/' +
                          jobsites[j].client_uuid +
                          '?tab=jobSites&&jobSiteID=' +
                          jobSiteId +
                          '">View Job Site</a>' +
                          '</div>' +
                          '</div>';
                      } else {
                        contentString =
                          '<div class="content">' +
                          '<h1 class="firstHeading">' +
                          infoWindowHeader +
                          '</h1>' +
                          '<div id="bodyContent">' +
                          '<h4 style="margin:5px 0;">' +
                          currentAddress +
                          '</h4>' +
                          '</div>' +
                          '</div>';
                      }

                      var pos = new window.google.maps.LatLng(
                        parseFloat(results[0].geometry.location.lat()),
                        parseFloat(results[0].geometry.location.lng())
                      );
                      markerOptions = {
                        map: localMap,
                        position: pos,
                      };

                      if (jobsites[j].client_type == 'Business') {
                        var businessIcon;
                        if (jobsites[j].jobPhase) {
                          businessIcon =
                            self.buildingURLs[jobsites[j].jobPhase]['icon'];
                        }
                        markerOptions.icon = {
                          url: businessIcon,
                          scaledSize: new window.google.maps.Size(31, 47),
                        };
                      } else if (jobsites[j].client_type == 'Residence') {
                        var houseIcon;
                        if (jobsites[j].jobPhase) {
                          houseIcon =
                            self.houseURLs[jobsites[j].jobPhase]['icon'];
                        }
                        markerOptions.icon = {
                          url: houseIcon,
                          scaledSize: new window.google.maps.Size(31, 47),
                        };
                      }
                    }
                  }

                  marker = new window.google.maps.Marker(markerOptions);
                  marker.jobSiteId = jobSiteId;
                  infowindow = new window.google.maps.InfoWindow();
                  allInfoWindows.push(infowindow);

                  window.google.maps.event.addListener(
                    marker,
                    'click',
                    (function (marker, content, infowindow, allInfoWindows) {
                      return function () {
                        allInfoWindows.forEach((window) => {
                          window.close();
                        });
                        infowindow.setContent(content);
                        infowindow.open(localMap, marker);
                      };
                    })(marker, contentString, infowindow, allInfoWindows)
                  );

                  window.google.maps.event.addListener(
                    marker,
                    'closeInfo',
                    (function (marker, content, infowindow) {
                      return function () {
                        infowindow.close(localMap, marker);
                      };
                    })(marker, contentString, infowindow)
                  );

                  self.allCurrentJobSiteMarkers.push(marker);

                  var bounds = new window.google.maps.LatLngBounds();
                  for (
                    var i = 0;
                    i < self.allCurrentJobSiteMarkers.length;
                    i++
                  ) {
                    bounds.extend(
                      self.allCurrentJobSiteMarkers[i].getPosition()
                    );
                  }

                  self.centerAndZoom(bounds);
                } else {
                  // if (self.inventory_type != "jobSites") {
                  //   console.log(
                  //     "aborted jobsites geocode because inventory_type changed"
                  //   );
                  // } else {
                  // console.log("geocode not working, heres the status: ", status);
                  // }
                }
              }
            );
          }

          // build the legend
          // console.log("renderJobSitesMap legend");
          var legend = document.createElement('div');
          legend.id = 'legend';
          legend.style.background = '#fff';
          legend.style.padding = '10px';
          legend.style.margin = '10px';
          legend.style.border = '3px solid #000';
          legend.innerHTML = "<h3 style='marginTop: 0;'>Legend</h3>";

          for (var key in this.legendIcons) {
            var type = this.legendIcons[key];
            var name = type.name;
            var icon = type.icon;
            var div = document.createElement('div');
            div.style.margin = '5px 0';
            div.innerHTML =
              '<img style="vertical-align: middle;width:20px;height:20px;margin:5px;" class="legendIcon" src="' +
              icon +
              '"> ' +
              name;
            legend.appendChild(div);
          }

          document.querySelector('#mapWrapper').appendChild(legend);
          this.map.controls[
            window.google.maps.ControlPosition.RIGHT_BOTTOM
          ].push(legend);
        }
      },
      async showCurrentJobSitePin() {
        if (!this.jobsites || !this.selected_jobsite_uuid) {
          return;
        }

        let localMap;
        var marker;
        var jobSiteId;
        var allInfoWindows = this.allInfoWindows;
        localMap = this.map;
        let self = this;

        let currentJobSite = this.jobsites.find((site) => {
          return site.uuid == self.selected_jobsite_uuid;
        });

        // if ( !currentJobSite ) {
        //   return;
        // }

        let address =
          currentJobSite.address +
          ', ' +
          currentJobSite.city +
          ', ' +
          currentJobSite.state;
        var contentString = 'Undetermined';
        var infowindow;
        var markerOptions = {};

        this.geocoder.geocode(
          {
            address: address,
          },
          function (results, status) {
            if (results && results[0]) {
              var resultsStreetNumber = '';
              for (var k = 0; k < results[0].address_components.length; k++) {
                if (
                  results[0].address_components[k].types[0] == 'street_number'
                ) {
                  resultsStreetNumber =
                    results[0].address_components[k].short_name;
                }
              }
              if (currentJobSite.address.indexOf(resultsStreetNumber) > -1) {
                var currentAddress =
                  currentJobSite.address +
                  ', ' +
                  currentJobSite.city +
                  ', ' +
                  currentJobSite.state;

                jobSiteId = currentJobSite.uuid;

                var infoWindowHeader = currentJobSite.name || 'No Site Name';

                contentString =
                  '<div class="content">' +
                  '<h1 class="firstHeading">' +
                  infoWindowHeader +
                  '</h1>' +
                  '<div id="bodyContent">' +
                  '<h4 style="margin:5px 0;">' +
                  currentAddress +
                  '</h4>' +
                  '</div>' +
                  '</div>';

                markerOptions = {
                  map: localMap,
                  position: new window.google.maps.LatLng(
                    results[0].geometry.location.lat(),
                    results[0].geometry.location.lng()
                  ),
                  zIndex: 1,
                };
              }

              marker = new window.google.maps.Marker(markerOptions);
              marker.jobSiteId = jobSiteId;
              infowindow = new window.google.maps.InfoWindow();
              allInfoWindows.push(infowindow);

              window.google.maps.event.addListener(
                marker,
                'click',
                (function (marker, content, infowindow, allInfoWindows) {
                  return function () {
                    allInfoWindows.forEach((window) => {
                      window.close();
                    });
                    infowindow.setContent(content);
                    infowindow.open(localMap, marker);
                  };
                })(marker, contentString, infowindow, allInfoWindows)
              );

              window.google.maps.event.addListener(
                marker,
                'closeInfo',
                (function (marker, content, infowindow) {
                  return function () {
                    infowindow.close(localMap, marker);
                  };
                })(marker, contentString, infowindow)
              );

              self.allCurrentJobSiteMarkers.push(marker);
            } else {
              console.log('geocode error - status:');
              console.log(status);
            }
          }
        );
      },
      //load tenant's species list
      async loadTenantSpecies() {
        // console.log("loadTenantSpecies");

        // set up tenant uuid variable
        var tenant_uuid;

        // Try to determine which tenant is needed, since user may
        //  not be logged in and relying on either having an estimate or client
        // record available. If that fails, abort, because an empty tenant uuid will
        // retrieve all the records.

        if (this.estimate && this.estimate.tenant_uuid) {
          tenant_uuid = this.estimate.tenant_uuid;
        } else if (this.client && this.client.tenant_uuid) {
          tenant_uuid = this.client.tenant_uuid;
        } else if (this.$auth && this.$auth.userProfile) {
          tenant_uuid = this.$auth.userProfile.tenant_uuid;
        } else {
          console.log('could not get tenant uuid');
          return;
        }

        // execute database query (we only want active trees displayed here)
        var res_tenant_species = await Tenants.getTenantSpecies(tenant_uuid, {
          active: true,
        });

        // validate
        if (res_tenant_species) {
          // console.log( "tenant species: ", res_tenant_species )

          // get just the species uuids
          var speciesUUIDs = res_tenant_species.map((x) => x.species_uuid);

          //this should probable just be called for each uuid rather than as a group so that
          //there doesn't have to be the rematching stuff
          //translate the uuids in the master list to get common and latin name
          var res_master_species =
            await Tenants.getMasterSpeciesBySpeciesLookup({
              species_uuids: speciesUUIDs,
            });

          // validate
          if (res_master_species) {
            //save species response to species data table
            this.speciesList = res_master_species;

            //this rematches the tenant list uuid to the translated master list display
            //this is for alt name display and view purposes, but could probably be done in a much smarter way

            for (let i = 0; i < this.speciesList.length; i++) {
              var match = res_tenant_species.find((x) => {
                return x.species_uuid == this.speciesList[i].uuid;
              });

              this.speciesList[i].tenantSpeciesUUID = match.uuid;
              //if match has an alternate common name, make the row common name that
              this.speciesList[i].common_name = match.alt_common_name
                ? match.alt_common_name
                : this.speciesList[i].common_name;
              this.speciesList[i].active = match.active;
            }
          } else {
            console.log('failed to load master species');
          }
        } else {
          console.log('failed to load tenant species');
        }
      },
      addSingleSpecies: function () {
        this.addSingleDialog = true;
        setTimeout(() => {
          this.$refs.common_select.isMenuActive = false;
          this.$refs.latin_select.isMenuActive = false;
        }, 50);
      },
      loadLastTree: function () {
        var copyKeys = ['species', 'condition', 'dbh', 'height', 'notes'];
        var dummyTree = {};
        dummyTree.latLng = this.newTree.latLng;
        for (var key in this.lastAddedTree) {
          if (copyKeys.includes(key)) {
            dummyTree[key] = this.lastAddedTree[key];
          }
        }
        this.newTree = dummyTree;
        // console.log("new tree after load", this.newTree, dummyTree);
      },
      sortSpecies: function (field) {
        this.speciesList.sort((a, b) => {
          if (a[field].toLowerCase() < b[field].toLowerCase()) {
            return -1;
          } else if (a[field].toLowerCase() > b[field].toLowerCase()) {
            return 1;
          } else {
            return 0;
          }
        });
        // console.log("sorted species", this.speciesList);
      },
    },
  };
</script>

<style lang="scss">
  .popup {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.54);
    cursor: auto;
    background-color: white;
    border-radius: 4px;
  }
  .popup:after {
    content: '';
    position: absolute;
    bottom: -15px;
    left: 50%;
    transform: translateX(-50%);
    border-width: 15px 15px 0;
    border-style: solid;
    border-color: white transparent;
    display: block;
    width: 0;
  }
  .popup-title-bar {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    box-orient: center;
    -moz-box-orient: center;
    -webkit-box-orient: center;
    height: 40px;
    color: white;
    background-color: #2fa3f2;
    border-radius: 4px 4px 0 0;
  }
  .popup-title {
    padding-left: 16px;
    font-size: 20px;
    line-height: 64px;
    max-width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .popup-content.v-card__text {
    padding-top: 0;
    padding-bottom: 0;
  }
  .popup-content .row {
    padding: 4px;
  }
  #mapWrapper {
    position: relative;
    height: 95vh;
  }
  #mapDiv {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
  .button {
    transition: background-color 0.5s ease;
    background-color: #004370;
    /* dark blue */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
  }
  .button:hover {
    background-color: #49b6ff;
    /* dark blue */
  }
  #legend {
    font-family: Arial, sans-serif;
    background: #fff;
    padding: 10px;
    margin: 10px;
    border: 3px solid #000;
  }
  #legend h3 {
    margin-top: 0;
  }
  .thumbnailRow {
    max-height: 250px;
    overflow-y: auto;
  }
  #fileInput {
    display: none !important;
  }
</style>
