<!-- ----------------------------------------------------------------------- -->
<!--
name    :  WORK TASK LIST [ INVENTORY MAP ]

about   :  this is used in inventory map to list the worktasks for a specific tree.
           This does NOT split worktasks into sections.

type    :  component

props:  :

used by :  inventory-map

uses    :  simple-dialog-template
           work-task
           work-task-details
           header-view

route   :  none
 -->
<!-- ----------------------------------------------------------------------- -->
<template>
  <v-container class="work-task-list-inventory-map">
    <div v-if="enable_controls && !readonly" class="add-section">
      <v-toolbar flat color="transparent">
        <v-toolbar-title>
          <span class="headline"> Work Tasks </span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
    </div>
    <div class="work-task-panel">
      <v-card class="work-task-panel-v-card">
        <!-- add work task button -->
        <v-btn
          v-if="enableCatalogButton && !readonly"
          @click="openAddWorkTaskDialog()"
          class="ma-4 button-primary">
          Add Work Task
        </v-btn>

        <!-- line divider -->
        <v-divider v-if="worktasks && worktasks.length > 0" />

        <!-- list of tasks, each task object getting 3 lines to display -->
        <v-list
          three-line
          class="py-0"
          v-if="worktasks && worktasks.length > 0">
          <div
            class="ma-0 pa-0"
            v-for="(task, index) in worktasks"
            :key="worktasks[index].uuid">
            <v-list-item class="px-1 ml-n2">
              <v-list-item-action class="mr-1">
                <v-menu offset-y min-width="150" class="white">
                  <template>
                    <v-btn small color="primary" icon> </v-btn>
                  </template>
                </v-menu>
              </v-list-item-action>
              <v-list-item-content class="pa-0">
                <work-task
                  v-model="worktasks[index]"
                  summary
                  :readonly="readonly"
                  @save="forceReload()"
                  @loading="
                    () => {
                      $emit('loading');
                    }
                  "
                  :proposal="proposal"
                  :workorder_uuid="workorder ? workorder.uuid : null"
                  :workorder_closed="workorder_closed"
                  :pricing_method="worktasks[index].pricing_method"
                  v-bind:tenantSettings="tenantSettings">
                </work-task>
              </v-list-item-content>
              <v-list-item-avatar v-if="enableCatalogButton && !readonly">
                <!-- This menu is for moving a task -->
                <v-menu offset-y>
                  <template v-slot:activator="{on}">
                    <v-btn color="primary" icon v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="removeWorkTask(worktasks[index].uuid)">
                      <v-list-item-title>Delete this task</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-list-item-avatar>
            </v-list-item>
            <v-divider v-if="worktasks.length - 1 != index"></v-divider>
          </div>
        </v-list>
      </v-card>
    </div>
    <div class="estimate-totals" v-if="tenant">
      <v-container style="padding-right: 32px">
        <v-layout row class="mt-5">
          <v-spacer></v-spacer>
          <v-flex xs4>
            <div v-if="proposal" class="headline text-right">
              Subtotal: {{ currencySymbol + estimateSubtotal }}
            </div>
            <div v-if="proposal" class="headline text-right">
              Tax Rate{{ taxRate }}%
            </div>
            <div class="headline text-right">
              Estimate Total: {{ currencySymbol + estimateTotal }}
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
    <v-dialog
      class="add-work-task-dialog"
      v-model="addWorkTaskDialog"
      persistent
      max-width="600px">
      <v-card>
        <header-view
          dialog
          previous_page="NO_BACK_BUTTON"
          title="New Work Task"
          closeBtn
          :closeAction="addTaskCancel"
          :btnOptions="[
            {
              name: '',
              icon: 'mdi-check',
              action: saveNewTask,
              btnColor: 'white',
            },
          ]" />
        <v-card-text>
          <!-- Estimate Section controls -->
          <v-form ref="addDialogForm">
            <v-container>
              <v-row no-gutters>
                <v-col>
                  <div class="title">Section</div>
                  <v-select
                    v-if="estimateSections.length > 0"
                    v-model="selectedSection"
                    :disabled="!enableSectionSelect"
                    :items="sectionPicker"
                    label="Section" />
                </v-col>
                <v-spacer></v-spacer>
              </v-row>

              <!-- Price Catalog toggle -->
              <v-row no-gutters>
                <v-col>
                  <div class="title">Type</div>
                  <v-btn-toggle color="info" v-model="workTaskToggle" mandatory>
                    <v-btn text>
                      <span>Catalog</span>
                    </v-btn>
                    <v-btn text>
                      <span>Open Bid</span>
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>

              <!-- Price Category and type picker -->
              <v-row v-show="!workTaskToggle">
                <v-col cols="12" md="6" class="pb-0">
                  <div>
                    <div class="title">Category</div>
                    <div class="work-category-picker">
                      <v-select
                        v-model="selectedWorkCategory"
                        :items="workCategoryPicker" />
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" md="6" class="pb-0">
                  <div>
                    <div class="title">Task</div>
                    <div class="work-type-picker">
                      <v-select
                        v-model="selectedWorkType"
                        :items="workTypePicker"
                        :disabled="disableSelector" />
                    </div>
                  </div>
                </v-col>
              </v-row>

              <!-- work task add forms -->
              <v-row>
                <v-col class="py-0">
                  <work-task-details
                    v-if="currentWorkTask"
                    v-bind:tenantSettings="tenantSettings"
                    adding_new_task
                    adding_to_tree
                    ref="workTaskDetails"
                    v-model="currentWorkTask"
                    :pricing_method="addForm" />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="addSnackbar" :timeout="5000">
      Record has been saved
      <v-btn class="button-primary" text @click="addSnackbar = false">
        Close
      </v-btn>
    </v-snackbar>
    <v-dialog v-model="sectionDialog" persistent max-width="600px">
      <v-card color="grey lighten-4" min-width="350px" flat>
        <header-view
          dialog
          previous_page="NO_BACK_BUTTON"
          title="Create New Section"
          closeBtn
          :closeAction="
            () => {
              sectionDialog = false;
            }
          "
          :btnOptions="[
            {
              name: '',
              action: this.saveNewSection,
              btnColor: 'white',
              icon: 'mdi-check',
            },
          ]" />
        <v-card-text>
          <v-form ref="sectionDialogForm">
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm6 md4>
                  <v-text-field
                    label="Section Name"
                    v-model="estimateSection.name"></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showCatalogDescriptions" width="500">
      <v-card color="grey lighten-4" min-width="350px" flat>
        <v-toolbar color="info" flat dark>
          <v-toolbar-title>Catalogs and Items</v-toolbar-title>
        </v-toolbar>

        <v-card-text>
          <v-container>
            <v-layout>
              <v-flex xs12>
                <div>
                  <b>Work Category Description</b>
                </div>
                <div v-if="workcategory">
                  {{ workcategory.description || 'Unavailable' }}
                </div>
                <div>
                  <b>Work Type Description</b>
                </div>
                <div v-if="worktype">
                  {{ worktype.description || 'Unavailable' }}
                </div>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="showCatalogDescriptions = false">
            Done
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- .................................................................... -->
    <!-- ..................                          ........................ -->
    <!-- .................................................................... -->

    <simple-dialog-template
      :open="notifyDialog"
      dialogTitle="Notice"
      dialogButtonOne="OK"
      :dialogText="notifyDialogText"
      @buttonOne="notifyDialog = false" />

    <simple-dialog-template
      :open="worktaskDeleteConfirmDialog"
      dialogTitle="Delete Work Task"
      dialogText="Deleting a work task cannot be undone, are you sure you wish to delete this?"
      dialogButtonOne="Confirm"
      dialogButtonTwo="Cancel"
      @buttonOne="removeWorkTaskConfirmed"
      @buttonTwo="worktaskDeleteConfirmDialog = false" />
  </v-container>
</template>

<script>
  // components
  import SimpleDialogTemplate from '@/components/simple-dialog-template';
  import WorkTask from '@/components/worktasks/work-task';
  import WorkTaskDetails from '@/components/worktasks/work-task-details';
  import HeaderView from '@/components/header-view';

  // mixins
  import Localization from '@/mixins/localization';

  // services
  import Jobs from '@/services/Jobs.service.js';
  import Tenants from '@/services/Tenants.service.js';
  import Users from '@/services/Users.service.js';

  export default {
    name: 'WorkTaskListInventoryMap',
    components: {
      'simple-dialog-template': SimpleDialogTemplate,
      'work-task': WorkTask,
      'work-task-details': WorkTaskDetails,
      'header-view': HeaderView,
    },
    mixins: [Localization],
    props: {
      proposal: {
        type: Boolean,
        default: false,
      },
      enable_controls: {
        //en
        type: Boolean,
        default: false,
      },
      enable_status: {
        //show the selector for changing work task status
        type: Boolean,
        default: false,
      },
      enable_catalogs: {
        //shows the 'add task' button for adding a task from a catalog
        type: Boolean,
        default: false,
      },
      enable_openbid: {
        //show the 'open bid' task button
        type: Boolean,
        default: false,
      },
      show_section_totals: {
        //show the totals for each section (only applies if sections are enabled)
        type: Boolean,
        default: false,
      },
      show_totals: {
        //show the totals for everything below the work tasks
        type: Boolean,
        default: false,
      },
      move_workorder: {
        //
        type: Boolean,
        default: false,
      },
      show_status: {
        //show the totals for everything below the work tasks
        type: Boolean,
        default: false,
      },
      readonly: {
        //prevent any work tasks or sections from being added or modified
        type: Boolean,
        default: false,
      },
      trees: {
        type: Array, // used for showing species associated to work tasks
        default: () => [],
      },
      approved: {
        // used only for allowing approved checkboxes to function until the estimate has been approved
        type: Boolean,
        default: false,
      },
      tree_uuid: {
        default: '',
        type: String,
      },
      dbh: {
        default: '',
        type: String,
      },
      height: {
        default: '',
        type: String,
      },
      workorder: {
        type: Object,
        default: () => {},
      },
      estimate: {
        type: Object,
        default: () => {},
      },
      workorder_closed: {
        default: null,
        type: Boolean,
      },
      tenantSettings: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        addOpenBidForm: false,
        addSnackbar: false,
        addWorkTaskDialog: false,
        catalogSelected: false,
        currentWorkTask: {},
        deleteUUID: '',
        estimateSection: {},
        estimateSections: [],
        estimateSubtotal: '0.00',
        isNewWorkOrderDialogVisible: false,
        newWorkOrder: {},
        notifyDialog: false,
        notifyDialogText: '',
        panels: [],
        sectionDialog: false,
        sectionsPanel: [],
        sectionTotals: [],
        selectedSection: '',
        selectedWorkCategory: '',
        selectedWorkType: '',
        showCatalogDescriptions: false,
        speciesList: [],
        tenant: null,
        tenant_uuid: null,
        workcategories: [],
        workcategory: null,
        worktaskDeleteConfirmDialog: false,
        worktasks: [],
        workTaskToggle: 0,
        worktype: null,
        worktypes: [],
      };
    },
    computed: {
      currencySymbol() {
        var symbol = this.tenantSettings.currency_symbol
          ? this.tenantSettings.currency_symbol
          : '$';
        return symbol;
      },
      workCategoryPicker() {
        // console.log( this.workcategories )

        //when the array of workcategories changes, we rebuild the selection options
        //and set the selected option to be the first in the list.
        var i;
        var text;
        var value;
        var items = [];
        if (this.workcategories.length > 0) {
          for (i = 0; i < this.workcategories.length; i++) {
            value = i;
            text =
              this.workcategories[i].name +
              ' - ' +
              this.workcategories[i].pricing_method;
            items.push({
              value: value,
              text: text,
            });
            // if ( i == 0 ) {
            //   this.selectedWorkCategory = items[0]['value']
            // }
          }
        }
        return items;
      },
      workTypePicker() {
        var i;
        var text;
        var value;
        var items = [];
        for (i = 0; i < this.worktypes.length; i++) {
          value = i;
          text = this.worktypes[i].name;
          items.push({
            value: value,
            text: text,
          });
          // if ( i == 0 ) {
          //   this.selectedWorkType = items[ 0 ][ 'value' ]
          // }
        }

        return items;
      },
      disableSelector() {
        if (this.worktypes.length > 0) {
          return false;
        }
        return true;
      },
      estimateTotal() {
        if (this.proposal) {
          var tax_rate = this.tenant.tax_rate ? this.tenant.tax_rate * 0.01 : 0;
          var total =
            Number.EPSILON +
            parseFloat(this.estimateSubtotal) +
            this.estimateSubtotal * tax_rate;
          var roundedTotal = Math.round(total * 100) / 100;
          return roundedTotal.toFixed(2);
        } else {
          return this.estimateSubtotal;
        }
      },
      taxRate() {
        var tax_type = this.tenant.tax_type
          ? ' (' + this.tenant.tax_type + ')'
          : '';
        var tax_rate = this.tenant.tax_rate ? this.tenant.tax_rate : '0.0';
        return tax_type + ': ' + tax_rate;
      },

      listItemContentClass: function () {
        if (this.enableCatalogButton && !this.readonly) {
          return 'px-3';
        } else {
          return '';
        }
      },
      addForm: function () {
        //only display the form to add a work task if we have selected a work category and type
        //  OR we have selected an openbid option.
        var form = '';

        // console.log( "addform: ", this.workTaskToggle, this.worktype, this.workcategory )
        if (this.workTaskToggle == 0) {
          if (this.worktypes[this.selectedWorkType] && this.workcategory) {
            if (this.workcategory.pricing_method == 'HOURLY') {
              form = 'HOURLY';
            } else if (this.workcategory.pricing_method == 'DBH') {
              form = 'DBH';
            } else if (this.workcategory.pricing_method == 'HEIGHT') {
              form = 'HEIGHT';
            } else if (this.workcategory.pricing_method == 'FLAT') {
              form = 'FLAT';
            }
          }
        } else if (this.workTaskToggle == 1) {
          form = 'OPENBID';
        }
        // console.log( form )
        return form;
      },
      sectionPicker: function () {
        let i = 0;
        let items = [];
        for (i = 0; i < this.estimateSections.length; i++) {
          items.push({
            value: this.estimateSections[i].uuid,
            text: this.estimateSections[i].name,
          });

          if (i == 0) {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.selectedSection = items[0]['value'];
          }
        }
        return items;
      },
      enableCatalogButton: function () {
        //hopefully i didnt break anything by requiring a catalog be selected
        if (this.enable_catalogs) {
          return true;
        }
        return false;
      },
      enableOpenBidButton: function () {
        if (this.enable_openbid && this.estimateSections.length > 0) {
          return true;
        }
        return false;
      },
      enableSectionSelect: function () {
        if (this.estimateSections.length > 0) {
          return true;
        }
        return false;
      },
      workTaskTotal: function () {
        let total = 0;
        let i = 0;
        for (i = 0; i < this.worktasks.length; i++) {
          total += parseInt(this.worktasks[i].subtotal);
        }
        return total;
      },
      sections: function () {
        var i = 0;
        for (i = 0; i < this.worktasks.length; i++) {
          if (!this.worktasks[i].section) {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.worktasks[i].section = 'Ungrouped';
          }
        }
        const sections = this.worktasks.map((x) => x.section);

        return [...new Set(sections)]; //note the javascript spread operator (...) (hate this syntax)
      },
    },
    watch: {
      selectedWorkCategory() {
        if (this.readonly) {
          return;
        }

        // if tenant has workcategories set up, set selected default
        if (this.workcategories && this.workcategories.length > 0) {
          this.workcategory = this.workcategories[this.selectedWorkCategory];
        }
      },

      async workcategory() {
        if (this.readonly) {
          return;
        }

        if (this.workcategory && this.workcategory.hasOwnProperty('name')) {
          this.currentWorkTask.workcategory = this.workcategory.name;
        }
        if (
          this.workcategory &&
          this.workcategory.hasOwnProperty('pricing_method')
        ) {
          this.currentWorkTask.pricing_method =
            this.workcategory.pricing_method;

          if (!this.currentWorkTask.tree_uuid) {
            this.currentWorkTask.dbh = '';
            this.currentWorkTask.height = '';
          }

          if (this.workcategory.pricing_method == 'HOURLY') {
            this.currentWorkTask.units = '';
          } else {
            this.currentWorkTask.units = 1;
          }
        }

        if (this.workcategories[this.selectedWorkCategory].uuid) {
          // Get the access token from the auth wrapper
          const accessToken = await this.$auth.getTokenSilently();
          this.worktypes = [];
          await this.loadWorkTypes(
            this.workcategories[this.selectedWorkCategory].uuid,
            accessToken
          );
        } else {
          // console.log( "Invalid workcategory_uuid" );
          this.worktypes = [];
        }
      },
      workTaskToggle(after) {
        if (this.readonly) {
          return;
        }

        // console.log( "workTaskToggle watch" )
        if (after == 0) {
          this.openAddWorkTaskDialog();
        } else if (after == 1) {
          this.addOpenBid();
        }
      },
      selectedWorkType() {
        if (this.readonly) {
          return;
        }

        // console.log( "selectedWorkType" )

        // we need to reset this to null before getting a new list, otherwise if
        // it was on array value 1 previously, it won't trigger a new event for a new
        // list. We want to ignore the rest of this if its on a null value until a
        // new array value is given
        if (this.selectedWorkType == null) {
          return;
        }

        if (
          this.worktypes[this.selectedWorkType] &&
          this.worktypes[this.selectedWorkType].hasOwnProperty('name')
        ) {
          this.currentWorkTask.worktype =
            this.worktypes[this.selectedWorkType].name;
        }
        if (
          this.worktypes[this.selectedWorkType] &&
          this.worktypes[this.selectedWorkType].hasOwnProperty('rate')
        ) {
          this.currentWorkTask.cost =
            this.worktypes[this.selectedWorkType].rate;
        }

        if (this.workcategory && this.workcategory.hasOwnProperty('name')) {
          this.currentWorkTask.workcategory = this.workcategory.name;
        }
        if (
          this.workcategory &&
          this.workcategory.hasOwnProperty('pricing_method')
        ) {
          this.currentWorkTask.pricing_method =
            this.workcategory.pricing_method;

          if (!this.currentWorkTask.tree_uuid) {
            this.currentWorkTask.dbh = '';
            this.currentWorkTask.height = '';
          }

          if (this.workcategory.pricing_method == 'HOURLY') {
            this.currentWorkTask.units = '';
          } else {
            this.currentWorkTask.units = 1;
          }
        }
        // console.log( "selectedSection", this.selectedSection )

        this.currentWorkTask.estimate_section_uuid = this.selectedSection;
      },
      selectedSection(after) {
        if (this.readonly) {
          return;
        }
        // console.log( "selectedSection" )

        this.currentWorkTask.estimate_section_uuid = after;
      },
      // workcategory( after, before ) {
      //   // console.log( "workcategory watch" )
      //   // console.log( before, after )
      //   if ( after && after.hasOwnProperty( 'name' ) ) {
      //     this.currentWorkTask.workcategory = after.name
      //   }
      //   if ( after && after.hasOwnProperty( 'pricing_method' ) ) {
      //     this.currentWorkTask.pricing_method = after.pricing_method
      //
      //     if ( !this.currentWorkTask.tree_uuid ) {
      //       this.currentWorkTask.dbh = ''
      //       this.currentWorkTask.height = ''
      //     }
      //
      //     if ( after.pricing_method == "HOURLY" ) {
      //       this.currentWorkTask.units = ''
      //     } else {
      //       this.currentWorkTask.units = 1
      //     }
      //   }
      //   this.$events.$emit( 'worktaskreload' )
      //   this.currentWorkTask.estimate_section_uuid = this.selectedSection
      // },
      // // filter( after, before ) {
      // //   for ( var property in after ) {
      // //     if ( after.hasOwnProperty( property ) ) {
      // //       if ( after[ property ] != before[ property ] ) {
      // //         this.$events.$emit( 'worktaskreload' )
      // //         this.loadEstimateSections(accessToken);
      // //         // this.openAllPanels();
      // //         break;
      // //       }
      // //     }
      // //   }
      // // },
      trees() {
        this.matchWorkTasksAndSpecies();
      },
      tree_uuid() {
        this.forceReload();
      },
      estimateSubtotal(newVal) {
        this.$emit('totalpriceupdate', newVal);
      },
    },
    async created() {
      // all components that use this component must pass in an estimate
      if (!this.estimate) {
        console.log(
          'work-task-list: estimate not passed in! all views that use this component must pass in an estimate'
        );
        return;
      }

      // validate that we can get the tenant uuid
      this.tenant_uuid = this.estimate.tenant_uuid;

      // Get tenant tax rate & type
      this.loadTaxInfo(this.tenant_uuid);

      // Get tenant species (must be done before loadWorkTasks)
      await this.loadTenantSpecies(this.tenant_uuid);

      // Get worktasks
      await this.loadWorkTasks(this.tenant_uuid);

      // if (!this.readonly) {
      // set up new worktask object
      this.currentWorkTask = Jobs.blankWorkTask(
        this.tenant_uuid,
        this.estimate.client_uuid,
        this.estimate.job_uuid,
        this.estimate.uuid,
        this.tree_uuid || null,
        this.workorder ? this.workorder.uuid : null,
        this.dbh || null,
        this.height || null
      );

      // console.log( "current work task mounted: ", this.currentWorkTask )
      // console.log( "current workorder: ", this.workorder )

      // Get estimate sections
      await this.loadEstimateSections();

      // get workcategories if not in readonly mode
      // this check is important because this component gets
      // reused for the public estimate proposal link, and calling
      // this function will attempt to get an accesstoken and error out.
      if (this.readonly == false) {
        await this.loadWorkCategories();
      }

      // update totals
      this.updateTotals();

      this.$events.$on('worktaskreload', this.forceReload);
    },
    methods: {
      async loadTaxInfo(tenant_uuid) {
        this.taxInfo = await Tenants.getTaxInfo(tenant_uuid);
      },
      async loadWorkTasks(tenant_uuid) {
        this.worktasks = await Jobs.getWorkTasksByParams({
          tenant_uuid: tenant_uuid,
          client_uuid: this.estimate.client_uuid,
          job_uuid: this.estimate.job_uuid,
          estimate_uuid: this.estimate.uuid,
          tree_uuid: this.tree_uuid,
          workorder_uuid: this.workorder ? this.workorder.uuid : null,
        });

        // get species list for the species column of table
        this.matchWorkTasksAndSpecies();
      },
      async loadEstimateSections() {
        var res = await Jobs.getEstimateSectionsByEstimate(this.estimate.uuid);

        if (res) {
          this.estimateSections = res;
          // if ( this.estimateSections.length > 0 ) {
          //   this.selectedSection = this.estimateSections[ 0 ].uuid;
          // }
          // console.log(this.estimateSections, res)
        } else {
          this.estimateSections = [];
        }
      },

      async loadWorkTypes(workcategory_uuid, accessToken) {
        // important - set selection to null before pulling
        // new values so that the event gets triggered correctly
        this.selectedWorkType = null;

        this.worktypes = await Users.getWorkTypes(
          {
            tenant_uuid: this.tenant_uuid,
            workcategory_uuid: workcategory_uuid,
            status: 'Active',
          },
          accessToken
        );

        // reset default selected type to the top one
        // note: must come after loading worktypes!
        this.selectedWorkType = 0;
      },
      async loadWorkCategories() {
        const accessToken = await this.$auth.getTokenSilently();

        this.workcategories = await Users.getWorkCategories(
          {
            tenant_uuid: this.tenant_uuid,
            status: 'Active',
          },
          accessToken
        );

        // console.log( "workcategories: ", this.workcategories )

        // reset default selected category to the top one
        // note: must come after loading workcategories!
        this.selectedWorkCategory = 0;
      },
      updateTotals: function () {
        this.estimateSubtotal = this.getSubTotals();
        this.getSectionTotals();
      },
      getEstimateTotal: function () {
        let total = 0;
        var i = 0;
        var x = 0;
        for (i = 0; i < this.estimateSections.length; i++) {
          for (x = 0; x < this.worktasks.length; x++) {
            if (
              this.worktasks[x].estimate_section_uuid ==
              this.estimateSections[i].uuid
            ) {
              total =
                total +
                Number.parseFloat(this.getWorkTaskTotal(this.worktasks[x]));
            }
          }
        }
        return total;
      },
      getWorkTaskTotal(entry) {
        var total = 0;
        total = this.getWorkTaskSubTotal(entry) - entry.discount;
        return total;
      },
      getWorkTaskSubTotal(entry) {
        //returns work task subtotal, which is the amount before discounts are applied.
        var subtotal = 0;
        //manhour amount calculation
        if (entry.pricing_method == 'HOURLY') {
          //cost times units
          subtotal = entry.cost * entry.units;
        }
        //dbh amount calculation
        else if (entry.pricing_method == 'DBH') {
          //cost times dbh, times units
          subtotal = entry.cost * entry.dbh * entry.units;
        }
        //height amount calculation
        else if (entry.pricing_method == 'HEIGHT') {
          //cost times height, times units
          subtotal = entry.cost * entry.height * entry.units;
        }
        //quickprice amount calculation
        else if (entry.pricing_method == 'FLAT') {
          subtotal = entry.cost * entry.units;
        }
        //openbid amount calculation
        else if (entry.pricing_method == 'OPENBID') {
          subtotal = entry.cost * entry.units;
        }
        return subtotal;
      },
      getPreviewEstimateTotal: function () {
        let total = 0;
        var i = 0;
        var x = 0;
        for (i = 0; i < this.estimateSections.length; i++) {
          if (this.proposal) {
            if (this.estimateSections[i].approved) {
              for (x = 0; x < this.worktasks.length; x++) {
                if (
                  this.worktasks[x].estimate_section_uuid ==
                  this.estimateSections[i].uuid
                ) {
                  total =
                    total +
                    Number.parseFloat(this.getWorkTaskTotal(this.worktasks[x]));
                }
              }
            }
          }
        }
        return total;
      },
      getSubTotals: function () {
        let total = 0;
        if (this.proposal) {
          total = this.getPreviewEstimateTotal();
        } else {
          total = this.getEstimateTotal();
        }
        return total.toFixed(2);
      },

      getSectionTotals: function () {
        var totals = [];
        var i = 0;
        var x = 0;
        for (i = 0; i < this.estimateSections.length; i++) {
          let total = 0;
          for (x = 0; x < this.worktasks.length; x++) {
            if (
              this.worktasks[x].estimate_section_uuid ==
              this.estimateSections[i].uuid
            ) {
              total =
                total +
                Number.parseFloat(this.getWorkTaskTotal(this.worktasks[x]));
            }
          }
          totals.push(total.toFixed(2));
        }

        this.sectionTotals = totals;
      },
      async saveNewSection() {
        // console.log( "saveNewSection", this.estimateSection )
        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();

        var confirmation = await Jobs.createEstimateSection(
          this.estimateSection,
          accessToken
        );

        if (confirmation) {
          // console.log( "saveNewSection confirmation: ", confirmation )
          await this.loadEstimateSections();
          // this.currentSection = this.estimateSection.uuid;
          this.sectionDialog = false;

          // this will open the new expansion panel
          this.sectionsPanel.push(this.estimateSections.length - 1);
        } else {
          console.log('saveNewSection confirmation failed: ', confirmation);
        }
      },
      openAllPanels() {
        this.panels = [...Array(this.sections).keys()].map((k, i) => i);
      },

      // Executes on tab switch over to the catalog section of the new work task dialog
      openAddWorkTaskDialog(sectionUUID) {
        // clears up and resets any form fill from the previous tab
        this.currentWorkTask = Jobs.blankWorkTask(
          this.tenant_uuid,
          this.estimate.client_uuid,
          this.estimate.job_uuid,
          this.estimate.uuid,
          this.tree_uuid || null,
          this.workorder ? this.workorder.uuid : null,
          this.dbh || null,
          this.height || null
        );

        this.addWorkTaskDialog = true;

        if (this.$refs.workTaskDetails) {
          this.$refs.workTaskDetails.reset();
        }

        if (this.workTaskToggle) {
          return this.addOpenBid();
        }

        // the things below do get set by the watchers, but they were previously
        // left here. We need to check if they are actually needed or were just put here
        // as backup

        // sets catalog specific variables
        if (sectionUUID) {
          // console.log( sectionUUID )
          this.currentWorkTask.estimate_section_uuid = sectionUUID;
        } else {
          this.currentWorkTask.estimate_section_uuid = this.selectedSection;
        }
        // this.selectedSection = this.currentWorkTask.estimate_section_uuid;

        if (
          this.worktypes[this.selectedWorkType] &&
          this.worktypes[this.selectedWorkType].hasOwnProperty('name')
        ) {
          this.currentWorkTask.worktype =
            this.worktypes[this.selectedWorkType].name;
          this.currentWorkTask.cost =
            this.worktypes[this.selectedWorkType].rate;
        }
        if (this.workcategory && this.workcategory.hasOwnProperty('name')) {
          this.currentWorkTask.workcategory = this.workcategory.name;
          this.currentWorkTask.pricing_method =
            this.workcategory.pricing_method;

          if (!this.currentWorkTask.tree_uuid) {
            this.currentWorkTask.dbh = '';
            this.currentWorkTask.height = '';
          }

          if (this.workcategory.pricing_method == 'HOURLY') {
            this.currentWorkTask.units = '';
          } else {
            this.currentWorkTask.units = 1;
          }
        }
      },

      // Executes on tab switch over to the open bid section of the new work task dialog
      addOpenBid() {
        // console.log( "addOpenBid" )

        // clears up and resets any form fill from the previous tab
        this.currentWorkTask = Jobs.blankWorkTask(
          this.tenant_uuid,
          this.estimate.client_uuid,
          this.estimate.job_uuid,
          this.estimate.uuid,
          this.tree_uuid || null,
          this.workorder ? this.workorder.uuid : null,
          this.dbh || null,
          this.height || null
        );

        // sets open bid specific variables
        this.currentWorkTask.pricing_method = 'OPENBID';
        this.currentWorkTask.units = 1;
        this.currentWorkTask.cost = '';
        this.currentWorkTask.estimate_section_uuid = this.selectedSection;
      },

      addTaskCancel() {
        this.addWorkTaskDialog = false;
      },
      async saveNewTask() {
        // console.log( this.$refs.workTaskDetails, this.$refs.workTaskDetails.isValid() )
        if (!this.$refs.workTaskDetails.isValid()) {
          return;
        }
        // console.log( "current work task saveNewTask: ", this.currentWorkTask )

        const accessToken = await this.$auth.getTokenSilently();

        this.currentWorkTask.subtotal = Number.parseFloat(
          this.getWorkTaskTotal(this.currentWorkTask)
        ).toFixed(2);

        // console.log( "current work task saveNewTask 2: ", this.currentWorkTask )

        let res = await Jobs.createWorkTask(this.currentWorkTask, accessToken);

        if (res) {
          this.snackbar = true;
          this.addWorkTaskDialog = false;
          // reload worktask list in this component
          this.$events.$emit('worktaskreload');
          // forces worktask count reload in AppEstimateView
          this.$events.$emit('workTasksCountChanged');
        }
      },

      async forceReload() {
        // console.log( "forceReload WorkTaskList" )

        //forces a reload, in case another component needs to trigger a refresh in sibling components
        //called by an event watcher in the mounted section

        await this.loadWorkTasks();

        this.updateTotals();

        // this is used only in the AppWorkorderView and inventory map components.
        // It is responsible for updating status in the header view after changes to
        // individual worktasks
        this.$emit('onReload');
      },
      async removeWorkTask(uuid) {
        //save the uuid of the work task we want to delete, then open the confirm box.
        this.deleteUUID = uuid;

        // open dialog
        this.worktaskDeleteConfirmDialog = true;
      },
      async removeWorkTaskConfirmed() {
        // close dialog
        this.worktaskDeleteConfirmDialog = false;

        const accessToken = await this.$auth.getTokenSilently();

        //only triggered by the delete confirmation dialog, uuid is set when the work task delete option is triggered
        var res = await Jobs.deleteWorkTask(this.deleteUUID, accessToken);

        if (res) {
          // forces worktask count reload in AppEstimateView
          this.$events.$emit('workTasksCountChanged');
          //force reload
          this.$events.$emit('worktaskreload');

          // reset deleteUUID back to nothing
          this.deleteUUID = '';
        }
      },
      async removeEstimateSection(uuid) {
        //verify that this section has no work tasks
        var x = 0;
        var empty = true;
        for (x = 0; x < this.worktasks.length; x++) {
          if (this.worktasks[x].estimate_section_uuid == uuid) {
            empty = false;
          }
        }

        if (!empty) {
          this.notifyDialogText =
            'Only sections that do not contain tasks may be removed, please delete or move all tasks in order to remove this section.';
          this.notifyDialog = true;
        } else {
          const accessToken = await this.$auth.getTokenSilently();
          await Jobs.deleteEstimateSection(uuid, accessToken);
          await this.loadEstimateSections();
        }
      },

      //load tenant's species list
      async loadTenantSpecies(tenant_uuid) {
        // get species
        var res_species = await Tenants.getTenantSpecies(tenant_uuid, {
          active: true,
        });

        if (res_species) {
          if (!(res_species.length > 0)) {
            console.log('tenant species list was empty');
            return;
          }

          //get just the species uuids
          var speciesUUIDs = res_species.map((x) => x.species_uuid);

          //this should probable just be called for each uuid rather than as a group so that
          //there doesn't have to be the rematching stuff
          //translate the uuids in the master list to get common and latin name

          var res_lookup = await Tenants.getMasterSpeciesBySpeciesLookup({
            species_uuids: speciesUUIDs,
          });

          if (res_lookup) {
            //save species response to species data table
            this.speciesList = res_lookup;
            //this rematches the tenant list uuid to the translated master list display
            //this is for alt name display and view purposes, but could probably be done in a much smarter way
            this.speciesList.forEach((row) => {
              var match = res_species.find((tenantSpecies) => {
                return tenantSpecies.species_uuid === row.uuid;
              });
              row.tenantSpeciesUUID = match.uuid;
              //if match has an alternate common name, make the row common name that
              row.common_name = match.alt_common_name
                ? match.alt_common_name
                : row.common_name;
              row.active = match.active;
            });
          } else {
            console.log('error doing master species lookup');
          }
        } else {
          console.log('could not load tenant species');
        }
      },
      matchWorkTasksAndSpecies: function () {
        this.worktasks.forEach((entry) => {
          if (this.trees && this.trees.length) {
            this.trees.forEach((tree) => {
              if (entry.tree_uuid == tree.uuid) {
                this.$set(
                  entry,
                  'species',
                  this.speciesList.find(
                    (x) => x.tenantSpeciesUUID == tree.tenant_species_uuid
                  )
                );
              }
            });
          }
        });
        this.$events.$emit('worktaskSpeciesUpdated');
      },
    },
  };
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped>
  .v-expansion-panel {
    width: auto;
  }

  .taskDots {
    margin-right: 0 !important;
  }

  .v-expansion-panel-content__wrap {
    padding: 16px 0 !important;
  }

  .v-expansion-panels > :first-child {
    margin-top: 16px !important;
  }
</style>
