<!-- ----------------------------------------------------------------------- -->
<!--
name    : WORK TASK DETAILS

type    : component

used by : work-task,
          work-task-list-workorder-view
          work-task-section-list-readonly
          work-task-section-list

uses    : header-view

route   : none
 -->
<!-- ----------------------------------------------------------------------- -->
<template>
  <v-form ref="form" class="work-task-details">
    <v-row v-if="pricing_method != 'OPENBID' && !adding_new_task">
      <v-col cols="12" class="py-0 mt-1">
        <span class="title"
          >{{ currentWorkTask.workcategory }} - {{ currentWorkTask.worktype }}
          {{ !proposal ? 'Pricing Details' : '' }}</span
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-if="pricing_method == 'OPENBID'"
        cols="12"
        sm="6"
        class="py-0 mt-4">
        <v-text-field
          label="Category"
          v-model="currentWorkTask.workcategory"
          :readonly="readonly"
          :rules="validate_required"
          validate-on-blur></v-text-field>
      </v-col>
      <v-col
        v-if="pricing_method == 'OPENBID'"
        cols="12"
        sm="6"
        class="py-0 mt-4">
        <v-text-field
          label="Task"
          v-model="currentWorkTask.worktype"
          :readonly="readonly"
          :rules="validate_required"
          validate-on-blur></v-text-field>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col
        v-if="pricing_method != 'HOURLY'"
        cols="12"
        sm="6"
        :md="pricing_method == 'OPENBID' ? '4' : '6'"
        class="py-0">
        <v-text-field
          label="Qty"
          v-model="currentWorkTask['units']"
          hint="Count of the total units of work, ie: number of trees."
          type="number"
          :readonly="readonly"
          :rules="[...validate_required, ...validate_positive_number]"
          validate-on-blur></v-text-field>
      </v-col>
      <v-col
        v-if="taskAttr && taskAttr.show_units"
        cols="12"
        sm="6"
        class="py-0">
        <!-- this field is disabled when adding work task to a specific tree, since 
        height of a specific tree should not be able to be edited -->
        <v-text-field
          :disabled="
            (pricing_method == 'DBH' || pricing_method == 'HEIGHT') &&
            adding_to_tree
          "
          :label="taskAttr.units_label"
          v-model="currentWorkTask[taskAttr.units]"
          :suffix="
            getUnits(taskAttr.units_label, tenantSettings.units_of_measurement)
          "
          hint="Count of the variable units of work, ie: labor hours or DBH."
          required
          type="number"
          :readonly="readonly"
          :rules="[...validate_required, ...validate_positive_number]"
          validate-on-blur></v-text-field>
      </v-col>
      <v-col
        v-if="!proposal"
        cols="12"
        sm="6"
        :md="pricing_method == 'OPENBID' ? '4' : '6'"
        class="py-0"
        mt-4>
        <v-text-field
          label="Rate"
          v-model="currentWorkTask.cost"
          hint="Cost per each unit of work"
          :readonly="readonly"
          type="number"
          :rules="validate_money_amount"
          validate-on-blur
          :prefix="currencySymbol"
          @blur="
            formatMoney({
              object: 'currentWorkTask',
              attribute: 'cost',
              key: 'cost_hour_key',
            })
          ">
        </v-text-field>
      </v-col>
      <v-col
        v-if="!proposal"
        cols="12"
        sm="6"
        :md="pricing_method == 'OPENBID' ? '4' : '6'"
        class="py-0"
        mt-4>
        <v-text-field
          label="Discount"
          hint="Total discount to apply to this line item"
          persistent-hint
          v-model="currentWorkTask.discount"
          :readonly="readonly"
          type="number"
          :rules="validate_discount"
          validate-on-blur
          :prefix="currencySymbol"
          @blur="
            formatMoney({
              object: 'currentWorkTask',
              attribute: 'discount',
              key: 'discount_key',
            })
          "></v-text-field>
      </v-col>
      <v-col cols="12" class="py-0 mt-4">
        <v-text-field
          label="Notes"
          v-model="currentWorkTask.notes"
          :readonly="readonly"></v-text-field>
      </v-col>
    </v-row>
    <div>
      <div v-if="!proposal">
        <div class="title float-right">
          <b>{{ 'Total before discount:' }} </b>{{ currencySymbol + subtotal }}
        </div>
        <br />
        <br />
      </div>
      <div class="title float-right">
        <b>{{ 'Total' + (proposal ? ':' : ' after discount:') }} </b
        >{{ currencySymbol + total }}
      </div>
      <br /><br />
    </div>
  </v-form>
</template>
<script>
  // mixins
  import Forms from '@/mixins/forms';
  import Localization from '@/mixins/localization';

  export default {
    name: 'WorkTaskDetails',
    mixins: [Forms, Localization],
    props: {
      value: {
        type: Object,
        required: true,
      },
      pricing_method: {
        type: String,
        required: true,
      },
      proposal: {
        type: Boolean,
        default: false,
      },
      dialog: {
        type: Boolean,
        default: false,
      },
      readonly: {
        type: Boolean,
        default: false,
      },
      // this is true when dialog is for adding a new task (as opposed to viewing)
      // currently work-task-sections-list.vue and work-task-list-inventory-map.vue
      // are used for adding and work-task.vue is used for viewing
      adding_new_task: {
        type: Boolean,
        default: false,
      },
      // this is true when new work task is being added to a tree
      // currently used by work-task-list-inventory-map.vue
      adding_to_tree: {
        type: Boolean,
        default: false,
      },
      tenantSettings: {
        type: Object,
      },
    },
    data() {
      return {
        taskAttr: {},
        currentWorkTask: {},
      };
    },
    computed: {
      currencySymbol() {
        var symbol = this.tenantSettings.currency_symbol
          ? this.tenantSettings.currency_symbol
          : '$';
        return symbol;
      },
      total: function () {
        return Number.parseFloat(
          this.getWorkTaskTotal(this.currentWorkTask)
        ).toFixed(2);
      },
      subtotal: function () {
        return Number.parseFloat(
          this.getWorkTaskSubTotal(this.currentWorkTask)
        ).toFixed(2);
      },
    },
    watch: {
      pricing_method(newVal) {
        this.taskAttr = this.getTaskAttributes(newVal);
        this.reset();
      },
      value() {
        this.currentWorkTask = this.value;
      },
    },
    async created() {
      this.currentWorkTask = this.value;

      this.taskAttr = this.getTaskAttributes(this.pricing_method);
    },
    methods: {
      isValid: function () {
        return this.$refs.form.validate();
      },
      reset: function () {
        return this.$refs.form.resetValidation();
      },
      getWorkTaskTotal(entry) {
        var total = 0;
        total = this.getWorkTaskSubTotal(entry) - entry.discount;
        return total;
      },
      getWorkTaskSubTotal(entry) {
        //returns work task subtotal, which is the amount before discounts are applied.
        var subtotal = 0;
        //manhour amount calculation
        if (entry.pricing_method == 'HOURLY') {
          //cost times units
          subtotal = entry.cost * entry.units;
        }
        //dbh amount calculation
        else if (entry.pricing_method == 'DBH') {
          //cost times dbh, times units
          subtotal = entry.cost * entry.dbh * entry.units;
        }
        //height amount calculation
        else if (entry.pricing_method == 'HEIGHT') {
          //cost times height, times units
          subtotal = entry.cost * entry.height * entry.units;
        }
        //quickprice amount calculation
        else if (entry.pricing_method == 'FLAT') {
          subtotal = entry.cost * entry.units;
        }
        //openbid amount calculation
        else if (entry.pricing_method == 'OPENBID') {
          subtotal = entry.cost * entry.units;
        }
        return subtotal;
      },
      getTaskAttributes(pricing_method) {
        if (!pricing_method) {
          console.log('pricing_method was not passed in: ', pricing_method);
          return;
        }

        var x = {};

        switch (pricing_method) {
          case 'HOURLY':
            x.units_label = 'Hours';
            x.units = 'units';
            x.show_units = true;
            break;
          case 'DBH':
            x.units_label = 'DBH';
            x.units = 'dbh';
            x.show_units = true;
            break;
          case 'HEIGHT':
            x.units_label = 'Height';
            x.units = 'height';
            x.show_units = true;
            break;
          case 'OPENBID':
            x.units_label = 'Quantity';
            x.units = 'units';
            x.show_units = false;
            break;
          case 'FLAT':
            x.units_label = 'Quantity';
            x.units = 'units';
            x.show_units = false;
            break;
        }

        return x;
      },
    },
  };
</script>
<style></style>
